
import basecfg from "../utils/applocationCfg"
const baseUrl = basecfg.getBaseApi();
import axios from "axios";
import router from '../router/index'
import { Loading } from 'element-ui'
// ----------------------------------------------
// loading进度条设置
let globalLoading
function startLoading() {
   globalLoading = Loading.service({
      lock: true,
      text: '加载中…',
      background: 'rgba(0, 0, 0, 0.7)'
   })
}

let needLoadingRequestCount = 0
export function showFullScreenLoading() {
   if (needLoadingRequestCount === 0) {
      startLoading()
   }
   needLoadingRequestCount++
}
export function tryHideFullScreenLoading() {
   if (needLoadingRequestCount <= 0) return
   needLoadingRequestCount--
   if (needLoadingRequestCount === 0) {
      globalLoading.close()
   }
}
// --------------------------------------------
const baseApi = {
   // 圈圈
   base: {
      post(url, params, callback) {
         axios.post(baseUrl + url, params, {
            headers: {
               'Token': localStorage.getItem("Login_data") ? JSON.parse(localStorage.getItem("Login_data")).token : null,
            }
         }).then(res => {
            setTimeout(() => {
               callback(res)
            }, 1500);

         }).catch(error => {
            tryHideFullScreenLoading()
            if (error.response.status == 601) {
               callback({
                  data: {
                     "code": "601",
                     "message": "登录失效",
                     "result": false,
                     "success": true
                  }
               })
            } else {
               callback({
                  data: {
                     "code": "-2",
                     "message": "服务端错误",
                     "result": false,
                     "success": true
                  }
               })
            }
         })
      },
      get(url, params, callback) {
         // showFullScreenLoading()
         const that = this
         axios.get(baseUrl + url, {
            params: params,
            headers: { token: localStorage.getItem("Login_data") ? JSON.parse(localStorage.getItem("Login_data")).token : null }
         }).then(res => {

            callback(res)

         }).catch((error) => {
           
           
            if (error.response.status == 601) {
             
               callback({
                  
                  data: {
                     "code": "601",
                     "message": "登录失效",
                     "result": false,
                     "success": true
                  }
               })
            } else {
               callback({
                  data: {
                     "code": "-2",
                     "message": "服务端错误",
                     "result": false,
                     "success": true
                  }
               })
            }
           
         })
      },
      download(url, params, callback) {
         // showFullScreenLoading()
         const that = this
         axios.get(baseUrl + url, {
            params: params,
            'responseType':'blob',
            headers: { token: localStorage.getItem("Login_data") ? JSON.parse(localStorage.getItem("Login_data")).token : null }
         }).then(res => {

            callback(res)

         }).catch((error) => {
         
           
            if (error.response.status == 601) {
             
               callback({
                  
                  data: {
                     "code": "601",
                     "message": "登录失效",
                     "result": false,
                     "success": true
                  }
               })
            } else {
               callback({
                  data: {
                     "code": "-2",
                     "message": "服务端错误",
                     "result": false,
                     "success": true
                  }
               })
            }
           
         })
      },
   },
   post(url, params, callback) {
      showFullScreenLoading()
      baseApi.base.post(url, params, (res) => {
         
         if (res.data.code == 601) {
            tryHideFullScreenLoading();
            localStorage.removeItem("Login_data"),
          router.push("/login")
           router.go(0)
         } else {
            tryHideFullScreenLoading();
            callback(res)
         }
      })
   },
   postImage(url, params, callback) {
      showFullScreenLoading()
      axios.post(baseUrl + url, params, {
         'Content-Type': 'multipart/form-data',

         headers: {
            'Token': localStorage.getItem("Login_data") ? JSON.parse(localStorage.getItem("Login_data")).token : null,
         }
      }).then(res => {
         tryHideFullScreenLoading()
         setTimeout(callback(res), 500)
         // callback(res)
      }).catch((error) => {
         tryHideFullScreenLoading()
         if (error.response.status == 601) {
         
            localStorage.removeItem("Login_data"),
               router.push("/login")
            router.go(0)
         }
      })
   },
   get(url, params, callback) {
      baseApi.base.get(url, params, (res) => {

         if (res.data.code == 601) {
          
            localStorage.removeItem("Login_data"),
               router.push("/login")
            router.go(0)
         } else {

            callback(res)

         }
      })
   },
   
};
//抛出api 
export default baseApi;