import 'babel-polyfill'
import promise from 'es6-promise'
promise.polyfill()

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axios from 'axios'
import 'amfe-flexible'
import VueAxios from 'vue-axios'
import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import store from './store'
import uweb from 'vue-uweb'


import VueDirectiveImagePreviewer from 'vue-directive-image-previewer'
import 'vue-directive-image-previewer/dist/assets/style.css'
Vue.use(VueDirectiveImagePreviewer) 

Vue.use(uweb,{siteId:'1280420444',debug:false})
// Vue.use(uweb,{siteId:'1280439332',debug:false})
Vue.use(ElementUI);
// 一键复制
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
Vue.use(Viewer)
Viewer.setDefaults({
  Options: { 'inline': true, 'button': true, 'navbar': true, 'title': true, 'toolbar': true, 'tooltip': true, 'movable': true, 'zoomable': true, 'rotatable': true, 'scalable': true, 'transition': true, 'fullscreen': true, 'keyboard': true, 'url': 'data-source' }
})


import ant from 'ant-design-vue'
Vue.use(ant)
import loginApi from "./api/loginApi"
// import api from './api/index'
// Vue.prototype.$api=api;
//

Vue.config.productionTip = false

// 格式化时间
import moment from 'moment'; //导入模块
moment.locale('zh-cn'); //设置语言 或 moment.lang('zh-cn'); 
Vue.prototype.$moment = moment;//赋值使用
import basecfg from "./utils/applocationCfg"

var axiosInstance = axios.create({
  baseURL: basecfg.getBaseApi(),
  //baseURL: "http://localhost:8802/",
  timeout: 30000,
  headers: { "Content-Type": "application/json" },
  withCredentials: false
});

axiosInstance.defaults.headers = {
  "Content-Type": "application/json"
};




Vue.prototype.$axios = axiosInstance;
Vue.use(VueAxios, axios);
import { Message } from "element-ui";
// 设置登录过期时间（一天）
let EXPIRESTIME = 86400000;

router.beforeEach((to, from, next) => {
  store.state.currPage=to.name
  if (to.name!="privacy"&&to.name!="trime"&&to.name!="iframeBack"&&to.name!="pay"&&(navigator.userAgent.match(/(iPhone|iPod|Android|ios|iOS|iPad|Backerry|WebOS|Symbian|Windows Phone|Phone)/i))) {
    window.location.href = "/h5";
    return;
  }
  // window.location.href = "http://h5.wyw666.com";
  let url 
  if(location.href.includes('share')){
      let id = location.href.split('&')[1].split('=')[1]
      let type = location.href.split("=")[1].split("&")[0]
      if(type=="goods"){
        url = `${location.origin}/#/GoodsDetial?id=${id}` 
      }
      if(type=='companygoods'){
        url = `${location.origin}/#/commodetial?id=${id}` 
      }
       window.location.href = `${url}`
  } 
  //判断页面是够需要登录(判断to.meta.needLogin是不是true)
  let needLogin = to.meta.needLogin
  if (needLogin) {
    //这个页面需要登录之后才能进入
    //判断是否登录
    let isLogin = localStorage.getItem('Login_data')
    if (isLogin) {
      if(isLogin){
        loginApi.checkLogin("",res=>{
          if(res.data.code==200){
             if(!res.data.result){
                  localStorage.removeItem("Login_data")
                  router.go(0)
             }
          }
        })
     }
      // isLogin = JSON.parse(isLogin)
    
      let date = new Date().getTime();
      if (date - isLogin.startTime > EXPIRESTIME) {
        localStorage.removeItem('token');
        Message.error("请先登录")
        router.push({ path: "/login" })
        this.$router.go(0)
      }
      else {
        next()
        
    
      }
    } else {
     
      Message.error("请先登录")
      router.push({ path: "/login" })
    }
  } else {
    //这个页面不需要登录直接下一步
    next()
  }

  
})

window.Vue = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
