import baseApi from './baseApi.js';
const imApi = {
	// 通过ID获取im用户信息
	getUserList(params,callback){
		baseApi.get('center/imuser/getUserList?ids='+params.ids,null,callback)
	},
	// 获取当前用户im账号密码
	getCurrUser(params,callback){
		baseApi.get('center/imuser/getCurrUser'+params,null,callback)
	},
	// 获取指定公司所有人im客服
	getCompanyUserList(params,callback){
		baseApi.get('center/imuser/getCompanyUserList?companyId='+params.companyId,null,callback)
	},
	// 获取获取客服信息
	
	getServiceList(params,callback){
		baseApi.get('center/imuser/getServiceList'+params,null,callback)
	},
	// 获取在线客服类表
		
	getOnlineService(params,callback){
		baseApi.get('center/imuser/getOnlineService'+params,null,callback)
	},
}
//抛出api 
export default imApi