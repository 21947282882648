<template>
  <div class="pub">
    <el-dialog
      :visible.sync="totaldialog"
      v-if="totaldialog"
      width="33%"
      :append-to-body="true"
      :before-close="clear"
      :close-on-click-modal="false"
     v-loading="loading" 
    >
      <div class="result">
        <el-form  :rules="rule" ref="addGoods" :model="addGoods" label-width="110px">
        
          <el-form-item label="品线" prop="productline">
            <el-select
              v-model="addGoods.productline"
              placeholder="请选择"
              filterable
              @change="changFirstInstitution"
              :disabled="this.tabIndex == 1"
            >
              <el-option
                v-for="item in productLine"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          
            <el-form-item label="品名" prop="productname" >
            <el-select
              v-model="addGoods.productname"
              :disabled="this.tabIndex == 1"
                @change="changname"
              placeholder="请选择"
              filterable
            >
              <el-option
              
                v-for="item in productNam"
                :key="item.value"
                
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="规格" class="guig">
          <el-input
              placeholder=""
              v-model="thick"
              :disabled="true"
            ></el-input>
          </el-form-item>
          <el-form-item label=""  class="kongb">
            <p>厚度：</p>
            <input
             class="el-inputs"
        onkeyup="value=value.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/,'$1')"
        
              placeholder=""
              v-model="addGoods.thickness"
              @blur="aniw"
              :disabled="this.tabIndex == 1"
            >
            <p>宽度：</p>
            <input
             class="el-inputs"
        onkeyup="value=value.replace(/[^\d{1,}\d{1,}|\d{1,}]/g,'')"
              placeholder=""
               
              :disabled="this.tabIndex == 1"
              v-model="addGoods.width"
              @blur="aniw"
            >
            <p>长度：</p>
            <input
             class="el-inputs"
              placeholder=""
             onkeyup="value=value.replace(/[^\d{1,}\d{1,}|\d{1,}|C]/g,'')"
            
          
              v-model="addGoods.lengths"
              :disabled="this.tabIndex == 1"
              @blur="aniw"
            >
          </el-form-item>
          <el-form-item label="钢厂" prop="steelmill">
            <el-select
            filterable
              v-model="addGoods.steelmill"
              @change="gFirstInstitution"
              :disabled="this.tabIndex == 1"
              placeholder="请选择"
            >
              <el-option
                v-for="item in steelmill"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="材质" prop="material">
            <el-select
              v-model="addGoods.material"
              :disabled="this.tabIndex == 1"
              placeholder="请选择"
              filterable 
            
            >
              <el-option
                v-for="item in material"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="质保书" v-if="!this.$store.state.home.spotpurchasing" prop="warranty">
            <el-select
              v-model="addGoods.warranty"
              :disabled="this.tabIndex == 1"
              @change="bchangFirstInstitution"
              placeholder="请选择"

            >
              <el-option
                v-for="item in warranty"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
             <el-form-item prop="projectname"  label="项目名称">
            <el-input
              placeholder="请输入项目名称"
              v-model="addGoods.projectname"
            ></el-input>
          </el-form-item>
        
          <el-form-item prop="number"  label="采购数量(吨)">
            <el-input
              placeholder="请输入数量"
              v-model="addGoods.number"
               onkeyup="value=value.replace(/[\u4e00-\u9fa5]/ig,'')"
            ></el-input>
          </el-form-item>
            <el-form-item prop="number" label="采购期望价">
            <el-input
              placeholder="请输入采购期望价"
              v-model="addGoods.price"
            ></el-input>
          </el-form-item>
         
          <el-form-item prop="color" label="颜色" >
            <el-input
           
              placeholder="请输入颜色"
              :disabled="this.tabIndex == 1"
              v-model="addGoods.color"
            ></el-input>
          </el-form-item>
          <el-form-item label="出厂时间" v-if="!this.$store.state.home.spotpurchasing" prop="manufacturedate">
            <!-- <el-select v-model="addGoods.manufacturedate" placeholder="请选择">
              
             </el-select> -->
            <div class="datepicker">
              <el-date-picker
                v-model="addGoods.manufacturedate"
                type="date"
                placeholder="选择日期"
                   align="right"
                :disabled="this.tabIndex == 1"
              >
              </el-date-picker>
            </div>
          </el-form-item>
             <el-form-item label="期望交货时间"  prop="delivertime" v-if="this.$store.state.home.spotpurchasing">
            <!-- <el-select v-model="addGoods.manufacturedate" placeholder="请选择">
              
             </el-select> -->
            <div class="datepicker">
              <el-date-picker
                 align="right"
                v-model="addGoods.delivertime"
                type="date"
                
                placeholder="选择日期"
                :disabled="this.tabIndex == 1"
              >
              </el-date-picker>
            </div>
          </el-form-item>
         
             <el-form-item label="交货地"  prop="deliverplace" >
            <el-select
              v-model="addGoods.deliverplace"
              :disabled="this.tabIndex == 1"
              placeholder="请选择"
              filterable
            >
              <el-option
                v-for="item in storageplace"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="zinclayer" label="锌层">
            <!-- <el-input
              placeholder="请输入..."
              :disabled="this.tabIndex == 1"
              v-model="addGoods.zinclayer"
            ></el-input> -->
                  <el-select
              v-model="addGoods.zinclayer"
              :disabled="this.tabIndex == 1"
             
              placeholder="请选择"
            >
              <el-option
                v-for="item in yinceng"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="质量等级">
            <el-select v-model="addGoods.qualitylevel" placeholder="请选择" :disabled="this.tabIndex == 1">
              <el-option
                v-for="item in qualitylevel"
                :key="item.value"
                :label="item.name"
                :value="item.value"
                
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="涂油">
            <el-select
              v-model="addGoods.oil"
              :disabled="this.tabIndex == 1"
              placeholder="请选择"
            >
              <el-option
                v-for="item in oil"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="表面工艺"  >
            <el-input
              placeholder="请输入..."
              :disabled="this.tabIndex == 1"
              v-model="addGoods.surfaceprocess"
            ></el-input>
          </el-form-item>

          <el-form-item label="有花无花">
            <el-select
              :disabled="this.tabIndex == 1"
              v-model="addGoods.pattern"
              placeholder="请选择"
            >
              <el-option
                v-for="item in pattern"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div class="publicb">
        <div>
          <span>备注</span>
          <el-input
            type="textarea"
          
            :autosize="{ minRows: 2, maxRows: 4 }"
            placeholder="请输入补充说明。如：商品涂层、表面、公差等。"
            v-model="addGoods.remark"
            :disabled="this.tabIndex == 1"
          >
          </el-input>
         
        </div>
       
      </div>
      <div class="bottom">
        <div>
          <el-button type="danger" @click="opengoods('addGoods')">提交</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { Message } from "element-ui";
import GoodsureLog from "./goodsurelog.vue";
import goodsApi from "../../../api/goodsApi";
import { log } from '../../../utils/EMedia_x1v1_3.4.1';

export default {
  data() {
    return {
       loading:false,
       rule: {
         
           mainimage: [
               { required: true, message: '请选择主图' },
          ],
            productline: [
               { required: true, message: '请选择品线',  },
          ],
            productname: [
               { required: true, message: '请选择品名',  },
          ],
            thickness: [
               { required: true, message: '请选择厚度', },
          ],
            width: [
               { required: true, message: '请选择宽度',  },
          ],
             steelmill: [
               { required: true, message: '请选择钢厂',  },
          ],

           material: [
               { required: true, message: '请选择材质', },
          ],

            warranty: [
               { required: true, message: '请选择保证书',  },
          ],
           number: [
               { required: true, message: '请填写数量',  },
          ],
          price : [
               { required: true, message: '请填写价格' ,},
          ],
           color: [
               { required: true, message: '非彩涂请输入本色',  },
          ],
                manufacturedate: [
               { required: true, message: '请选择出厂时间', },
          ],
            storageplace: [
               { required: true, message: '请选择存放地',  },
          ],
           zinclayer: [
               { required: true, message: '请填写锌层',  },
          ],
            projectname: [
               { required: true, message: '请填写项目名称',  },
          ],
             delivertime: [
               { required: true, message: '请填写交货时间', },
          ],
             deliverplace: [
               { required: true, message: '请填写交货地', },
          ],
            
          
          
      },
      dialogImageUrl: "",
      dialogVisible: false,
      // 申请表单
      addGoods: {
         
      },

      //规格
      thick: null,

      // 材质
      material: [],
      // 存放地
      storageplace: [],
      // 质量等级
      qualitylevel: [
        {
          label: "正品",
          value: true,
        },
        {
          label: "协议",
          value: false,
        },
      ],
         yinceng: [
        {
          label: "有",
          value: true,
        },
        {
          label: "无",
          value: false,
        },
      ],
      // 钢厂
      steelmill: [],
      // 钢厂对应的材质
      material: [],
      // 商品类型
      goodstype: [],
      //  品名
      productName: [],
      // 品线
      productLine: [],
      // 保证书
      warranty: [
        {
          label: "是",
          value: true,
        },
        {
          label: "否",
          value: false,
        },
      ],
      // 涂油
      oil: [
        {
          label: "请选择",
          value: "",
        },
        {
          label: "是",
          value: true,
        },
        {
          label: "否",
          value: false,
        },
      ],
      // 有花无花
      pattern: [
        {
          label: "是",
          value: true,
        },
        {
          label: "否",
          value: false,
        },
      ],
      //  品名value
      linevalue: "",
      //  品名数组
      productNam: [],
      // 品线数组
      lineshzu: [],
      // 钢厂value
      gangvalue: "",
      // 钢厂数组
      steelmil: [],
      // 材料数组
      materia: [],
     fileList:[],
      lable: "",
      radio: "1",
      checked: false,
      // 三级联动
      options: [],
      name: "上海钢铁贸也有限公司",
      labelPosition: "left",
      imageUrl: "",
      textarea2: "",
      dialogImageUrl: "",
      value: "",
    };
  },
  components: {
    GoodsureLog,
  },
  props: {
    addpublic: false,
    tabIndex: Number,
    goodid: "",
    totaldialog: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
  
    this.getGoodsParam();
  },

  methods: {
    // 上传失败时
    errorfl(){
       this.loading = false
    },
     // 上传时
    oprogress(){
      this.loading = true
    },
    parentHandleclick(e) {
      this.addGoods = e;
      
      // let urlStr = this.addGoods.images.split(","); //logo地址
     
          this.addGoods.images.forEach(item => {
            let obj = new Object();
            obj.url = item;
            this.fileList.push(obj);
          });
    },
    aniw() {
     console.log(this.addGoods.thickness)
      if (!this.addGoods.thickness || !this.addGoods.width||!this.addGoods.lengths) {
        
        this.thick = "";
      }else{
        this.thick =
          this.addGoods.thickness +
          "*" +
          this.addGoods.width +
          "*" +
          this.addGoods.lengths;
      }
    },
    // 获取商品参数
    getGoodsParam() {
      goodsApi.getGoodsParam("", (res) => {
        this.material = res.data.result.material;
        this.storageplace = res.data.result.storageplace;
        this.steelmill = res.data.result.steelmill;
        this.productLine = res.data.result.goodstype.productLine;
        this.productName = res.data.result.goodstype.productName;
         this.qualitylevel = res.data.result.qualitylevel;
        // 获取品名品线
        for (
          let index = 0;
          index < res.data.result.goodstype.productName.length;
          index++
        ) {
          const element = res.data.result.goodstype.productName[index];
          this.lineshzu.push(element);
          this.productName.pid = this.lineshzu.pid;
        }
        //获取钢厂
        for (let index = 0; index < this.steelmill.length; index++) {
         
          const element = this.steelmill[index].material;
          this.steelmil.push(element);
        
        }
       
      });
    },
     isInteger(obj) {
 return typeof obj === 'number' && obj%1 === 0
},
    clear() {
      this.$emit("clears", false);
      this.addGoods = {};
       this.fileList = []
    },
    beforeAvatarUpload(){
    
        this.loading = false
    },
    // 添加
    opengoods(addGoods) {
    //  console.log(isInteger(this.addGoods.lengths))   
    //  return
    //    if(this.addGoods.lengths.length>1){
    // if(this.addGoods.lengths!=parseInt(this.addGoods.lengths)){
    //         return  Message.error("请输入长度为0-100000的整数，或者一个字母C")
    //   }
    //   }
       
      this.$refs[addGoods].validate((valid) => {
          if (valid) {
                 if (!this.addGoods.thickness || !this.addGoods.width||!this.addGoods.lengths) {
         Message.error("请填写完整的规格")
         return;
      }else{
       
         if(this.addGoods.thickness<0||this.addGoods.thickness>10000){
         this.addGoods.thickness=''
           Message.error("输入厚度0到10000的数值")
          return;
      }
       
      if(this.addGoods.width<0||this.addGoods.width>10000){
      this.addGoods.width=''
           Message.error("输入宽度0到10000的数值")
             return;
        
      }
     
           if(this.addGoods.lengths.length>1){
    if(this.addGoods.lengths!=parseInt(this.addGoods.lengths)){
      this.addGoods.lengths=''
            return  Message.error("请输入长度为0-100000的整数，或者一个字母C")
      }else if(this.addGoods.lengths<0||this.addGoods.lengths>100000){
         this.addGoods.lengths=''
 return  Message.error("请输入长度为0-100000的整数，或者一个字母C")
      }
      
      }
      }
            if(this.$store.state.home.spotpurchasing){
           
               this.$emit("surees", false);
               this.$emit("emittogoods",this.addGoods);
            }
            else{
               if (this.$store.state.home.addpublic) {
        goodsApi.addGoods(this.addGoods, (res) => {
          if (res.data.code != 200) {
            return Message.error(res.data.message);
          } else {
            Message.success("添加成功");
             
            this.$parent.getGoodsListByState();
            this.$emit("surees", false);
                this.addGoods = {};
          }
        });
      } else {
        if (this.tabIndex != 1) {
          goodsApi.updateGoods({ id: this.goodid }, this.addGoods, (res) => {
          
            if (res.data.code != 200) {
              Message.error(res.data.message);
            } else {
                 Message.success("修改成功");
              this.$parent.getGoodsListByState();
            
              this.$emit("surees", false);
                this.dialogTableVisible = false;
               this.dialogImageUrl = "";
                 
            }
          });
        } else {
          goodsApi.editteGoods(
            {
              id: this.goodid,
              price: this.addGoods.price,
              number: this.addGoods.number,
            },
            (res) => {
            
              if (res.data.code != 200) {
                Message.error(res.data.message);
              } else {
                 Message.success("修改成功");
                this.$parent.getGoodsListByState();
             
                this.$emit("surees", false);
                  this.dialogTableVisible = false;
                  this.dialogImageUrl = "";
                 
              }
            }
          );
        }
          }
      }

    
          } else {
             if (!this.addGoods.thickness || !this.addGoods.width||!this.addGoods.lengths) {
         Message.error("请填写完整的规格")
         return;
      }else{ if(this.addGoods.thickness<0||this.addGoods.thickness>10000){
         this.addGoods.thickness=''
           Message.error("输入厚度0到10000的数值")
          return;
      }
       
      if(this.addGoods.width<0||this.addGoods.width>10000){
      this.addGoods.width=''
           Message.error("输入宽度0到10000的数值")
             return;
        
      }
     
           if(this.addGoods.lengths.length>1){
    if(this.addGoods.lengths!=parseInt(this.addGoods.lengths)){
      this.addGoods.lengths=''
            return  Message.error("请输入长度为0-100000的整数，或者一个字母C")
      }else if(this.addGoods.lengths<0||this.addGoods.lengths>100000){
         this.addGoods.lengths=''
 return  Message.error("请输入长度为0-100000的整数，或者一个字母C")
      }
      
      }
      }
      
            return Message.error("请填写完整");
          }
        });
    },
    handleAvatarSuccess(res, file) {
     
        this.loading = false
       if(file.response.code=="308"){
            return Message.error("文件上传失败，请重新上传");
       }
       else{
           this.addGoods.mainimage = file.response.result;
   
       }
     
      
    },
  
    
    handlePictureCardPreview(res,file) {
      
     if(res.code!=='200'){
       Message.error("文件上传失败，请重新上传");
         this.fileList.pop()
     
     }
     else{
      this.fileList.push(res.result);
      this.addGoods.images =  this.fileList
    
     }
     
    },
     //  图片数组
    // 2移除某一个
    handleRemove(file, fileList) {
      this.addGoods.images = fileList
    },
    // 品名选择器获取值
    changFirstInstitution(e) {
      this.linevalue = e;
  
      var imn = this.lineshzu.filter((item) => {
        return this.linevalue === item.pid;
      });  
      this.productNam = imn;
      
      this.addGoods.productname = this.productNam[0].value;
      
    },
    // 
    changname(){
    this.$forceUpdate();
    },
      
    // 钢厂选择器获取值
    gFirstInstitution(e) {
      this.materia=[];
      for (var i = 0; i < this.steelmill.length; i++) {
        if (this.steelmill[i].value == e) {
          var currmaterial = this.steelmill[i].material;
          if (currmaterial == null) {
            this.materia = this.material;
          } else {
            currmaterial.filter((item) => {
               var checkData= this.material.filter(curritem=>{
                 var slml=item;
                 var ml=curritem.value;
                 return slml==ml;
               });
               if(checkData.length>0){
                 this.materia.push(checkData[0]);
               }
            });
          }
          break;
        }
      }
  



      
    },
    // 保证书
    bchangFirstInstitution(value) {
     
    },
   
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-dialog {
  min-width: 477px !important;
}
::v-deep .el-form-item__error {
    top: 20% !important;
    left: 0% !important;
    z-index: 999 !important;
}
.publicb {
  width: 90%;
  height: 250px;
  margin: 5px auto;
  div {
    margin-top: 15px;
  }
  span {
    float: left;
  }
  .span {
    font-size: 10px;
    line-height: 120px;
    margin-left: 5px;
    color: darkgray;
  }
  ::v-deep .el-upload--picture-card {
    background-color: #fbfdff;
    border: 1px dashed #c0ccda;
    border-radius: 6px;
    box-sizing: border-box;
    width: 88px;
    height: 88px;
    line-height: 88px;
    vertical-align: top;
    float: left;
  }

  ::v-deep .el-upload-list--picture-card .el-upload-list__item-thumbnail {
    width: 88px;
    height: 88px;
    float: left;
  }
  ::v-deep .el-upload-list--picture-card .el-upload-list__item {
    float: left;
    overflow: hidden;
    background-color: #fff;
    border: 1px solid #c0ccda;
    border-radius: 6px;
    box-sizing: border-box;
    width: 88px;
    height: 88px;
    margin: 0 8px 8px 0;
    display: inline-block;
  }
}
.result ::v-deep .el-form-item[data-v-33b23e58] .el-input .el-input__inner {
  border: 0 transparent !important;
  // float: right;
  text-align: right;

  line-height: 28px;

}
.result {
  .el-dialog {
    margin-left: 10px;
  }

  .avatar-uploader {
    width: 40px !important;
    float: right;
    height: 30px;
    line-height: 30px;
  }
   .el-form-item {
    border-bottom: 1px solid #ccc;
    padding: 0;
    margin: 5px;
    box-sizing: border-box;
    width: 90%;
    margin: 5px auto;
    ::v-deep .el-input .el-input__inner {
      border: 0 transparent !important;
     
      text-align: right;
    
    }

    ::v-deep .el-form-item__label {
      text-align: left;
    }
    .el-select {
      // width: 200px !important;
      // border: 1px solid red;
      float: right;
      
      // line-height: 110px;
    }
    .datepicker {
      // border: 1px solid red;
      width: 120px;
      float: right;
      line-height: 20px;
      height: 35px;
    }
    .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
      width: 138px;
    }
  }
  .guig .el-input {
    border: 1px solid rgba(0, 0, 0, 0.1);
    width: 147px;
    float: right;
  }
  .guig {
    border-bottom: 0px;
  }
  .kongb {
    border-bottom: 0px;
    ::v-deep .el-form-item__content {
      height: 40px;
      margin-left: 10px !important;
    }
  }

  .kongb p {
    display: inline-block;
    margin-left: 10px;
    //  margin-top: -30px;
    //  border: 1px solid red;
  }
 .el-inputs {
    border: 1px solid rgba(0, 0, 0, 0.1);
    width: 90px;
    height: 40px;
    //    margin-left:-150px;
    //  border: 1px solid red;
    //  margin-top: -10px;
    position: relative;
   outline: none;
   padding-left: 10px;
  }
  .kongb span {
    color: rgba(0, 0, 0, 0.05);
    // vertical-align: top;
  }

  ::v-deep .el-upload.el-upload--text {
    border: 1px solid #ccc;
    width: 35px;
    height: 35px;
  }
  ::v-deep .el-icon-plus.avatar-uploader-icon {
    line-height: 35px;
    font-size: 20px;
  }
}

.bottom .el-button {
  width: 90%;
  margin: 5px auto;
  margin-left: 25px;
  font-size: 16px;
}
</style>