<template>
  <div id="app">
    <div id="nav">
      <!-- <router-link to="/login">登录</router-link>  -->
      <!-- 测试一下是否成功 -->


      <!-- <div></div> -->
    </div>
    <router-view/>
  </div>
</template>
<script>
import WebIM from "./utils/WebIM";
import { mapState, mapActions } from "vuex";
import "./assets/css/app.css"
import loginApi from "./api/loginApi"
export default {
  data(){
    return{
      
    }
  },
  created(){
    
     this.checkLogin()
    },
 methods:{
      //  检测登录状态
    checkLogin(){
         let isLogin = localStorage.getItem('Login_data')
         if(isLogin){
            loginApi.checkLogin("",res=>{
              if(res.data.code==200){
                 if(!res.data.result){
                      localStorage.removeItem("Login_data")
                      this.$router.go(0)
                 }
              }
            })
         }
    },
      
         
 },
     
// 	beforeMount(){
// const userInfo = localStorage.getItem("userInfo") && JSON.parse(localStorage.getItem("userInfo"));
// 		if(userInfo){
// 			const userName = userInfo.userid;
// 			const password = userInfo.password;
// 			var options = {
// 				user: userName,
// 				pwd: password,
// 				appKey: WebIM.config.appkey
// 			};
// 			WebIM.conn.open(options);
// 		}
// 		},
	
  
}

</script>
<style lang="scss">
*{
  margin: 0;
  padding: 0;
  font-family: PingFang-SC-Medium, PingFang-SC;
}

.el-select .el-input .el-input__inner ,.el-cascader .el-input .el-input__inner  {
  border: 0px !important;
  text-align: right;
  background-color: rgba(0,0,0,0);
}
.popper__arrow{
  right: 10% !important;
  left: unset !important;
}
// .el-input .el-input__inner{
//    border: 0px !important;
// }
.five{
    //   border: 1px solid red;
      text-align: right;
  }


 
</style>
