<template>
  <div class="app">
    <div class="centerline">
      <div class="left">
        <section class="sec1">
          <ul>
            <li v-for="(item,index) in getLine" :key="index" :id="item.uuid" @click="clickLine(item)" ref="dataInfo" v-on:mouseout="test2()" v-on:mouseover="test1(item.uuid)">
               <img  src="../assets/index/1.png" alt="">
               <nav>
               <p class="hot">{{item.name}}专区 </p>
               </nav>
               <img  src="../assets/index/left.png" alt="">
             
            </li>
           
          </ul>
           <div :class="listxian?'list1':'list1 list12'"  v-on:mouseout="test3()" v-on:mouseover="test4()">
                  <div class="left">
                    <div class="centerline" >
                      <div class="top">
                        <h2>品名</h2>
                        <!-- <div class="more">
                          <a>更多</a>
                          <img src="../assets/index/left.png" alt="">
                        </div> -->
                        <!-- :to="{path:'specialistDetail',query:{id:item.uuid}}" -->
                      </div>
                      <div class="acount" >
                    <span v-for="(it,index) in productName" :key="index"> <span href="" @click="publicname(it.name)"> {{it.name}}  </span></span>
                       
                      </div>
                    </div>
                  </div>
                 <div class="right"> 
                   <div v-for="(item,index) in steelmill" :key="index">
                    <router-link :to="{path:'AteelHome',query:{id:item.uuid,name:item.name}}"  class="righta">   
                    <div class="item" >
                      <img :src="item.image" alt="">  
                        <div class="detil">
                        <h2>{{item.name}}</h2>
                        <p>{{item.mainProducts}}</p>
                        </div>
                      </div>
                    </router-link> 
                      
                       </div> 
                 </div>
              </div>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
import getlineApi from "../api/getlineApi"
export default {
  data() {
    return {
      listxian:false,
 getLine:[],
 lineId:"",
//  存储品名数据
saveproductName:[],
//  品名数据
 productName:[],
// 钢厂数据
 steelmill:[],
    };
  },
  created(){
    
      this.getProductLine()
      this.getProductName()
     
 
  },
  components: {
    
  },
  methods:{
    clickLine(item){
          
          this.$router.push({path:'search',query:{pll:item,}})
    },
    publicname(name){
       this.$uweb.trackEvent("商品类型筛选","点击",name)
           this.$router.push({path:'search',query:{scarch:name}})
    },
    // 获取品线
getProductLine(){
  getlineApi.getProductLine("",(res)=>{
   
    this.getLine = res.data.result
//获取品名
  })
},
getProductName(){
getlineApi.getProductName({lineId:this.lineId},(res)=>{
  
  })
},
test1(id){
   var that=this;
   this.listxian = true
   for(var i=0;i<that.saveproductName.length;i++){
      var item=that.saveproductName[i];
      if(item.id==id){
        that.productName=item.productName;
        that.steelmill=item.steelmill;
        return;
      }
   }
    getlineApi.getProductNameOrSteelMillsb({lineId:id},(res)=>{
      if(res.data.code==200){
        this.productName =res.data.result.productName
        this.steelmill = res.data.result.steelmill
        that.saveproductName.push({
          id:id,
          productName:this.productName,
          steelmill:this.steelmill,
        })
      }

   
   
  })
   
    
},
test3(){
 this.listxian = false
},
test4(){
  this.listxian = true
},
test2(){
  this.listxian = false
}
  }
};
</script>
<style  lang="scss" scoped>
*{
  margin: 0;
  padding: 0;
}
.app .centerline {
  // border: 1px solid red;
  display: flex;
  justify-content: space-around;
  /* border: 1px solid violet; */
}

/* ---------------------- */
.sec1{
  position: relative;
  

}
.sec1 ul {
  width: 236px;
  /* border: 1px solid; */
  /* border: 1px solid blue; */
//  width: 100%;
 height: 472px;
  background: rgb(251,251,251);
  margin-top: 0px;
  // margin-left: -10px;
//  border: 1px solid red; 
  // overflow-x: hidden;
  overflow-y: scroll;
  margin-left: 20px;
}
.sec1 ul::-webkit-scrollbar {
  /*滚动条整体样式*/
  width : 10px;  /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
  }

.sec1 > ul > li {
  list-style-type: none;
  height: 58px;
  width: 220px;
  color: black;
  // border: 1px solid red;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-bottom: 1px solid #ccc;
  background-color: white;
  img{
    margin-top: -6px;
  }
  nav{
      //  border: 1px solid red;
       width: 30%;
   }
 .hot{
   width: 120px;
height: 22px;
font-size: 16px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 600;
color: rgba(0, 0, 0, 0.87);
line-height: 22px;
margin-bottom: 6px;
   }
   .two span{
     height: 17px;
font-size: 12px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: rgba(0, 0, 0, 0.56);
line-height: 17px;

   }
   .two span:nth-child(1){
     margin-right: 6px;
   }
  
}
.sec1 > ul > li:nth-last-child(1){
  border: 1px solid transparent;
}
.sec1 > ul {
  position: relative;
  // border: 1px solid red;
  
  
}
.sec1 ul li:hover {
  background-color: rgb(241,186,188);
  cursor: pointer;
  // color:rgb(241,186,188);
}

.sec1 ul li:hover p span{
  color:rgb(241, 48, 48) ;
}
.sec1 ul li:hover nav p{
  color:rgb(241, 48, 48) ;
}
.sec1 ul li:hover img{
   color: rgb(241, 48, 48) ;
}
 .list1{ 
  width: 950px;
  // height: 514px;
  height: 100%;
  // border: 1px solid red;
  position: absolute;
  left: 93%;
  top: 0px;
  background-color: white;
  z-index: 100;
  min-width: 740px;
  .left{
    width: 60%;
    float: left;
    height: 100%;
    box-shadow: 2px 2px 15px #ccc;
   
    .centerline{
  // border: 1px solid red;
  display: block;
      padding: 10px;
    }
    .top{
   h2{
     width: 32px;
height: 22px;
font-size: 16px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: rgba(0, 0, 0, 0.87);
line-height: 22px;
   }
//    .more{
//      a{
//        width: 24px;
// height: 17px;
// font-size: 12px;
// font-family: PingFangSC-Regular, PingFang SC;
// font-weight: 400;
// color: rgba(0, 0, 0, 0.56);
// line-height: 17px;
// margin-right: 5px;
//      }
//    }
    }
    .acount {
//  border: 1px solid red;
     margin-top: 10px;
    
    span{
        // width: 56px;
height: 20px;
font-size: 14px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: rgba(0, 0, 0, 0.56);
line-height: 20px;
margin-right: 5px;
display: inline-block;
cursor: pointer;
   a{
 text-decoration: none;
 color: rgb(139, 137, 137);
   }
// border: 1px solid red;
    }
    }
  }
  .right{
    width: 39%;
    // border: 1px solid red;
  float: right;
  height: 100%;
  .righta{
    text-decoration: none;
  }
  .item{
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    // height: 80px;
    padding:20px ;
    display: flex;
  align-items: center;
    box-sizing: border-box;
    .detil{
// height: 49px;
   width: 270px;
      p{
// height: 20px;
font-size: 14px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: rgba(0, 0, 0, 0.56);
// line-height: 30px;
      }
      h2{
        // width: 56px;
// height: 25px;
font-size: 18px;
font-family: PingFangSC-Medium, PingFang SC;
font-weight: 600;
color: rgba(0, 0, 0, 0.87);
// line-height: 25px;
      }
    }
    img{
     width: 54px;
height: 49px;
// vertical-align: middle;
// border: 1px solid red;
// display:block;
margin-top: 0px;
margin-right: 8px;
    }
    
  }
  }
}

.list12{
  display: none;
}
.sec1 > ul > li:hover div {
  display: block;
}

</style>