<template>
    <div class="nav-wrap">
    <el-dialog
  
  :visible.sync="dialogVisible"
  width="29%"
  :before-close="clear"
   :close-on-click-modal="false"
  >
    <el-form ref="formLabelAlign" :label-position="labelPosition" :rules="rule"  label-width="80px"  :model="formLabelAlign">
  <el-form-item label="产品信息">
          
            <div class="buttons">
              <el-button @click="openProduct" 
                >添加产品</el-button
              >
            <img src="" alt="">
   <!-- <el-upload
  class="avatar-uploader"
  action="https://jsonplaceholder.typicode.com/posts/"
  :show-file-list="false"
  :on-success="handleAvatarSuccess"
 >
  <img v-if="imageUrl" :src="imageUrl" class="avatar">
  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
</el-upload> -->
    </div>
  </el-form-item>
    <Public
              :totaldialog="totaldialog"
              @clears="clears"
              @surees="surees"
              @emittogoods="emittogoods"
               :close-on-click-modal="false"
            ></Public>
  <el-form-item label="标题" >
    <el-input v-model="title" placeholder="请输入你求购现货标题"></el-input>
  </el-form-item>
  <el-form-item label="求购详情"  >
    <!-- <el-input v-model="formLabelAlign.type"></el-input> -->
    <el-input 
    class="describe"
  type="textarea"
  :autosize="{ minRows: 2, maxRows: 4}"
  placeholder="请您较为详细的讲述要求购的现货信息，例如：品名、规格、钢材、颜色、锌层等等"
  v-model="describe">
</el-input>
  </el-form-item>
     <el-button type="danger" class="submit"  @click="sure(formLabelAlign)">发布</el-button>
</el-form>
</el-dialog>
 
    </div>
 
   
     

</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import { Message } from "element-ui";
import Top from "@/components/login/Top.vue";
import Foo from "@/components/login/Footer.vue";
import Public from "../../components/center/entre/product.vue";
import goodsApi from '../../api/goodsApi'
export default {
  data(){
    return{
      // 主图地址
      imageUrl:"",
       labelPosition: 'right',
       totaldialog:false,
        formLabelAlign:null, 
         title:"",
          describe:"",
          rule: {
             deliverimfor: [
               { required: true, message: '请填写完成商品信息', },
          ],
              title: [
               { required: true, message: '请填写标题', },
          ],
              describe: [
               { required: true, message: '请填写求购详情', },
          ],
            
          
          
      },
    }
  },

       props: {
    dialogVisible: {
      type: Boolean,
      default: false
    }},
 created(){
  
 },
  components: {
    // HelloWorld
    Top,
    Foo,
    Public
  },
  methods:{
    // 
    emittogoods(goods){
      this.formLabelAlign = goods
 
    },
    //关闭对话框
      clear () {
      this.$emit('clear', false);
    },
          // 添加现货采购
    sure (formLabelAlign) {
      // this.$emit('sure', false);
       if(!this.title){
           return   Message.error("请填写完成标题")
     } 
      if(!this.describe){
         return Message.error("请填写完成求购详情")
     }
  if(this.formLabelAlign){

    this.formLabelAlign.title = this.title
    this.formLabelAlign.describe =  this.describe
          
              this.$refs.formLabelAlign.validate((valid) => {
           if(valid){
                   goodsApi.getSpotsourcingList(this.formLabelAlign,res=>{
      
        if(res.data.code==200){
               Message({
              message: "恭喜您已成功发布求购信息",
              type: "success",
            });
           
          
             this.$refs.formLabelAlign.resetFields();
              this.$emit('sure', false);
        }
        else{
        Message.error(res.data.message)
        }
      })
           }
       })
  }
  else{
     Message.error("请填写完成的商品信息")
  }
    
    
    },
      clears(bool) {
      this.totaldialog = bool;
    },
    surees(bool) {
      this.totaldialog = bool;
    },
    // 上传成功
    handleAvatarSuccess(){},
//  添加产品
  openProduct() {
      this.totaldialog = true;
       this.$store.commit("spotpurchasing", true);
     
    },
     
  },
  mounted(){
   
   

  }
}
</script>
<style lang="scss" scoped>
.center{
    width: 90%;
    height: 700px;
    /* border: 1px solid red; */
  
}
.nav-wrap{
  // border: 1px solid red;
  height: 100%;
  width: 60%;
  margin: auto;
//  margin-top: 50px;
 min-width: 800px;
 .describe{
   ::v-deep.el-textarea__inner{
       height: 200px !important;
   }
   margin-left: 0px;
  } 
 ::v-deep .el-dialog{
    min-width: 477px !important;
  }

  .submit{
    width: 80%;
    // border: 1px solid red;
    margin-top: 30px;
    margin-left: 80px;
    
  }
  .information{
    
    width: 86%;
    float: left;
  }
  ::v-deep .el-upload {
    border: 1px solid #ccc;
    width: 10%;
    margin-left: 10px;
  }
}

  .buttons .el-button {
      width: 300px;
      // margin-left: -120px;
    }


</style>
