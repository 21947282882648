<template>
  <div class="app"  v-loading="loading"

  >

    <div class="bandertop">

    </div>
   <Top></Top>
     <div class="scrollstarea" v-show="pmhome">
       <div class="scrollleft">

      <img src="../assets/index/show.png" alt="">

    <div id="scroll_diva" class="fl">
       <div id="scroll_begina">
           <span class="pad_righta"> {{pmhome}}</span>
        </div>
        <div id="scroll_end"></div>
    </div>
    </div>
    <div>
      <!-- <i ></i> -->
       <img class="el-icon-circle-close" @click="closepmhom" src="../assets/index/clear.png" alt="">
    </div>
    </div>


    <div class="center">
      <div class="centerTop">
        <Header></Header>
      </div>

      <div class="centerMain">
        <div class="biaa">
          <div class="banner_left">
      <img src="../assets/index/banner_left.png" alt="">
          </div>
          <div class="centerItem">
            <div class="center">
              <Pageitem></Pageitem>
                <div class="bottomtop">


     <router-link to="search"> <div class="categorytitle">
         <div class="imgim"><img src="../assets/index/top.png" alt=""></div>
         <div>全部尾货</div>
           <div class="img"><img src="../assets/index/ri.png" alt=""></div>
      </div>
      </router-link>
      <span class="before">

      </span>
</div>
            </div>
            <div class="right">
              <Lunob></Lunob>
            </div>
          </div>
            <div class="banner_right">
<img src="../assets/index/banner_right.png" alt="">
          </div>
           </div>
          <div class="centerIist">
            <div class="item" v-for="(item,index) in optimumList" :key="index">
              <div class="vender">
                <viewer>
                <img
                  :src="item.minImage"
                  alt=""
                />
              </viewer>
                <div>
                <h3 class="nametext">{{item.name}}</h3>
                <p><span class="companyname">{{item.companyName}}</span><span v-if="item.currprice" class="select">优选 </span></p>

                </div>
              </div>
              <div class="inventory">
                <!-- <p class="ku">{{item.warehouseName}}</p> -->
                <p class="shi"><span>存放地 </span>| <span>{{item.storageplace}}</span></p>
              </div>
              <div class="amount">
                <p class="acount" v-if="item.price == 0"><span class="q">￥</span><span class="a">电议</span></p>
                <p class="acount" v-else><span class="q">￥</span><span class="a">{{item.price}}</span> /吨</p>
                <p class="count" v-if="item.currprice">现价：￥{{item.currprice}}/吨</p>
              </div>
              <div class="date">
                <p class="da">{{ item.createTime }}</p>

              </div>
                <router-link :to="{path:'GoodsDetial',query:{id:item.id}}">
                <div class="button">
                 <el-button type="danger">查看详情</el-button>

              </div>
                  </router-link>
            </div>
          </div>
  <!-- 分页 -->
     <!-- <el-pagination
      background

      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageNum"
      :page-size="pageSize"
      layout="  prev, pager, next"
      :total="totalCount"
    >
    </el-pagination> -->

<Pagination  class="el-pagination " v-show="optimumList.length>0" :num="alltotalCount" :limit="pageSize" :totalCount="totalPageCount" :totalPageCount='alllisttotalPageCount'></Pagination>
      </div>

    </div>


    <Foo></Foo>
  </div>
</template>
<script>
import { Message } from "element-ui";
import Top from "@/components/login/Top.vue";
import Foo from "@/components/login/Footer.vue";
import Header from "@/components/pagehome/HomeHeader";
import Pageitem from "@/components/pageitem";
import Lunob from "@/components/pagehome/lunob"
import goodsApi from "../api/goodsApi"
import loginApi from "../api/loginApi"
import Pagination from "@/components/prePage/pagination.vue";
import { log } from '../utils/EMedia_x1v1_3.4.1';


export default {
  data() {
    return {
      pmhome:"",
      num:0,
      pageNum:1,
      pageSize:10,
      totalCount:0,
      alltotalCount:0,
      totalPageCount:0,
      alllisttotalPageCount:0,
      optimumList:[],
      stock:"",
      loading:false,
      allListpublic:[]
    };
  },
  components: {
    Top,
    Foo,
    Pageitem,
    Header,
    Lunob,
    Pagination
  },
  mounted(){
    this.getProclamationInfo()
    this.getOptimumList()
    // const that = this

  },
  methods:{
     change(even){
 this.pageNum = even
 this.getOptimumList();
    },
        closepmhom(){
       this.pmhome = ""
    },
    // 滚动
     startScroll(){
         //文字横向滚动
    function ScrollImgLeft(){
        var speed=50;
        var MyMar = null;
        var scroll_begina = document.getElementById("scroll_begina");
        var scroll_end = document.getElementById("scroll_end");
        var scroll_diva = document.getElementById("scroll_diva");
        scroll_end.innerHTML=scroll_begina.innerHTML;
        function Marquee(){
            if(scroll_end.offsetWidth-scroll_diva.scrollLeft<=0)
                scroll_diva.scrollLeft-=scroll_begina.offsetWidth;
            else
                scroll_diva.scrollLeft++;
        }
        MyMar=setInterval(Marquee,speed);
        scroll_diva.onmouseover = function(){
            clearInterval(MyMar);
        }
        scroll_diva.onmouseout = function(){
            MyMar = setInterval(Marquee,speed);
        }
    }
    ScrollImgLeft();
     },
     // 获取公告
    getProclamationInfo(){
      loginApi.getProclamationInfo("home",res=>{
        if(res.data.code==200){
            this.pmhome = res.data.result.value;
        }
      })
    },
    //优选商品
   getOptimumList(){
  goodsApi.getOptimumList({pageNum:this.pageNum,pageSize:this.pageSize},(res)=>{
    if(res.data.code==200){
    this.optimumList = res.data.result.data
    console.log(res);
     this.totalPageCount = res.data.result.totalPageCount
     this.totalCount = res.data.result.totalCount;
     this.stock = res.data.result.data.stock
     for (let index = 0; index < this.optimumList.length; index++) {
            this.optimumList[index].createTime = this.$moment(
              this.optimumList[index].createTime
            ).format("yyyy/MM/DD ")
          }

    }
      this.surepagelist()

  })
},
// 优选和商品链接
     contentoptimum(){
          goodsApi.getOptimumList({pageNum:this.totalPageCount,pageSize:this.pageSize},(res)=>{

         if(res.data.code==200){
             for (let index = 0; index <  res.data.result.data.length; index++) {
            res.data.result.data[index].createTime = this.$moment(
              res.data.result.data[index].createTime
            ).format("yyyy/MM/DD ")
          }
           this.optimumList = res.data.result.data
            this.optimumList= this.optimumList.concat(this.allListpublic)


    }

  })
     },

     surepagelist(){
          goodsApi.List({pageNum:1,pageSize:this.pageSize,params:""},(res)=>{
            //debugger
        if(res.data.code==200){
          this.alllisttotalPageCount = res.data.result.totalPageCount + this.totalPageCount - 1
          this.alltotalCount =  res.data.result.totalCount + this.totalCount
          this.allListpublic =  res.data.result.data
            for (let index = 0; index < this.allListpublic.length; index++) {
            this.allListpublic[index].createTime = this.$moment(
              this.allListpublic[index].createTime
            ).format("yyyy/MM/DD ")
          }

        }
      })
     },
    // 所有商品
    allList(i){
      let pagenum = i -this.totalPageCount+1

      goodsApi.List({pageNum:pagenum,pageSize:this.pageSize,params:""},(res)=>{

        if(res.data.code==200){
           this.allListpublic =  res.data.result.data
           for (let index = 0; index <  this.allListpublic .length; index++) {
            this.allListpublic[index].createTime = this.$moment(
              this.allListpublic[index].createTime
            ).format("yyyy/MM/DD ")
          }

          this.optimumList=this.allListpublic
        }
      })
    }
  }
};
</script>
<style  lang="scss" scoped>
.bandertop{
  height: 90px;
   background: url('~@/assets/index/bandertop.jpg');
   background-position:100% 100%;
  background-size:100% 100%;
}
.bottomtop{
   width: 220px;
     border-radius: 5px 5px 0 0;
     background: rgb(233,79,85);
      position: absolute;
      top: -41px;
      left: -8px;
      height: 40px;
     a{
       text-decoration: none;
     }
}
.before{
  // border: 1px solid red;
  width: 10px;
    height: 10px;
    position: absolute;
    top: 36px;
    left: 106px;
   -webkit-transform: rotate(
45deg);
         background: rgb(233,79,85);
    z-index: 1;
}
    .categorytitle {
      // border: 1px silver solid;
      display: flex;
      width: 80%;
      margin: 12px auto;

      color: rgb(238, 238, 238);
      font-size: 14px;
      justify-content: space-between;


   .imgim{
     img{
        width: 15px;
     height: 15px;
     vertical-align:middle;
     }

   }
// margin-left: 33px;
    }



 .scrollstarea{
   display: flex;
   justify-content: space-between;
   align-items: center;
    width:100%;
    // border: 1px solid red;
  height: 40px;
background: #FFF9ED;
 }
 .scrollleft{
   padding-left: 30px;
     display: flex;
   justify-content: space-between;
   align-items: center;
 }
 .pad_righta{
   margin-left: 6px;
   letter-spacing: 2px;
  font-size: 14px;
  color: #FF6010 ;
 }
 #scroll_diva {
            height:30px;
            //  border: 1px solid red;
            line-height: 30px;
            overflow: hidden;
            white-space: nowrap;

            // background-color: #fff;
            color:  rgb(233,79,85);
            text-align: left;
        }
        #scroll_begina,#scroll_end {
        display: inline;
        }
        .el-icon-circle-close{
          margin-right: 20px;
        }
 .firimage {
    margin-left: 8px;
    width: 15px;
    position: relative;
    top: 3px;
  }
 .nametext{
   font-size: 14px !important;
     overflow: hidden !important;
       white-space: nowrap !important;
        text-overflow: ellipsis !important

    }
     .companyname{
           font-size: 10px;
        }
        .select{
          margin-left: 4px;
         width: 30px;
height: 14px;
line-height: 14px;
text-align: center;
color: white;
background: #E2241B;
border-radius: 2px;
font-size: 9px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
        }
.el-header,
.el-footer {
  width: 100%;
}

.center {
  width: 100%;
  // min-width: 1000px;

  .centerMain {
      border-top: 2px solid #e95157;
    width: 100%;

     min-width: 1380px;
    // height: 1132px;

    background: #fbfbfb;
    // border: 1px solid blue;
    height: 100%;
    padding-bottom: 10px;
  }
}
.centerTop {
  width: 100%;
  height: 146px;
  background: #ffffff;
  // border: 1px solid red;
}

.centerMain .centerItem {
  width: 100%;
  // border: 1px solid red;
  height: 510px;
  margin-bottom: 20px;
  display: flex;
  min-width: 800px;
  .center{
    position: relative;
  // border: 1px solid rgb(55, 9, 219);
  width: 200px;
  height: 480px;
  // margin-right: 10px;
  }
  .right{
    width:90%;
    min-width: 760px;
    margin-left: 10px;
  }
}
.biaa {
  //  border: 1px solid blue;
  width: 80%;
  margin: 0 auto;
  display: flex;
  min-width: 1380px;
  height: 470px;
  justify-content: space-between;
   .banner_left,.banner_right{
       img{
         width:183px;
         height: 470px;
       }
   }
}
.centerIist {
  width: 70%;
  padding: 0;
  margin: 20px auto;
  min-width:800px;

  // border: 1px solid red;
  .item {
    // border: 1px solid red;
    width: 100%;
    height: 110px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:10px 20px;
    box-sizing: border-box;
    background-color:white ;
    margin-top: 10px;
    margin-bottom: 10px;
    .vender{
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
        img{
            width: 80px;
            height: 80px;
            margin-right: 10px;
        }
        h3{
            width: 233px;
height: 20px;
font-size: 18px;
font-family: PingFangSC-Medium, PingFang SC;
font-weight: 600;
color: rgba(0, 0, 0, 0.77);
line-height: 20px;
// border: 1px solid red;
margin-bottom: 10px;
        }
        p{
            margin-top: 10px;
            // border: 1px solid red;
            width: 169px;
height: 20px;
font-size: 14px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: rgba(0, 0, 0, 0.56);
line-height: 20px;
        }
        div{
          p{
            display: flex;
            align-items: center;
            width: 250px;
            // border: 1px solid red;
            img{
              width: 20px;
              height: 20px;
              margin-left: 10px;
            }
          }
        }
    }
  }

  .inventory{
      .ku{
          width: 100px;
height: 22px;
font-size: 16px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 500;
color: rgba(0, 0, 0, 0.87);
line-height: 22px;
margin-bottom: 10px;
      }
      .shi{

height: 20px;
font-size: 14px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: rgba(0, 0, 0, 0.56);
line-height: 20px;
margin-top: 10px;
      }
  }
  .amount{
    width: 150px;
   .acount{
       margin-bottom: 10px;

       .q{
          //  width: 14px;
height: 20px;
font-size: 14px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #D0111A;
line-height: 20px;

       }
       .a{
     width: 63px;
height: 37px;
font-size: 20px;
font-family: PingFangSC-Semibold, PingFang SC;
font-weight: 600;
color: #D0111A;
line-height: 37px;
       }
       }
     .count{
         margin-top: 10px;
         width: 146px;
height: 20px;
font-size: 14px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: rgba(0, 0, 0, 0.56);
line-height: 20px;
//  border: 1px solid red;
     }
  }
  .date{
      .da{

height: 22px;
font-size: 16px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: rgba(0, 0, 0, 0.87);
line-height: 22px;
margin-bottom: 10px;
      }
      .te{
width: 63px;
height: 20px;
font-size: 14px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: rgba(0, 0, 0, 0.56);
line-height: 20px;
margin-top: 10px;
      }
      }
      .el-button{
        color: #D0111A;
        background-color: white;
      }
       .el-button:hover{
         background-color: rgb(233,79,85);
         color: white;
       }
}
// .button{
//   background-color: white;
//   color: #D0111A;
// }

.el-pagination {
  // border: 1px solid red;
   width:100%;
  margin: 20px auto;
}
</style>
