const imageZip = {
    beforeUpload(file) {
        return new Promise((resolve, reject) => {
            let isLt2M = file.size / 1024 / 1024 < 4 // 判定图片大小是否小于10MB
            if(!isLt2M) {
                reject()
            }
            let image = new Image(), resultBlob = '';
            image.src = URL.createObjectURL(file);
            image.onload = () => {
                // 调用方法获取blob格式，方法写在下边
                resultBlob = imageZip.compressUpload(image, file);
                resolve(resultBlob)
            }
            image.onerror = () => {
                reject()
            }
        })
    },
    compressUpload(image, file) {
        let canvas = document.createElement('canvas')
        let ctx = canvas.getContext('2d')
        let initSize = image.src.length
        let { width } = image, { height } = image
        canvas. width = width
        canvas.height = height
        ctx.fillRect(0, 0, canvas.width, canvas.height)
        ctx.drawImage(image, 0, 0, width, height)
        
        // 进行最小压缩0.1
        let compressData = canvas.toDataURL(file.type || 'image/jpeg', 0.2)
        
        // 压缩后调用方法进行base64转Blob，方法写在下边
        let blobImg = this.dataURItoBlob(compressData)
        return blobImg
    },
     
    /* base64转Blob对象 */
    dataURItoBlob(data) {
        let byteString;
        if(data.split(',')[0].indexOf('base64') >= 0) {
            byteString = atob(data.split(',')[1])
        }else {
            byteString = unescape(data.split(',')[1])
        }
        let mimeString = data
            .split(',')[0]
            .split(':')[1]
            .split(';')[0];
        let ia = new Uint8Array(byteString.length)
        for( let i = 0; i < byteString.length; i += 1) {
            ia[i] = byteString.charCodeAt(i)
        }
        return new Blob([ia], {type: mimeString})
    }
 
}
export default imageZip;