import baseApi from './baseApi.js';
const getlineApi = {
    // 获取品线
    getProductLine(params,callback){
    baseApi.get('goods/goodstype/getProductLine',params,callback)
 },
 //获取品名
 getProductName(params,callback){
    baseApi.get('goods/goodstype/getProductName?lineId='+params.lineId,null,callback)
 },
 //获取品名和钢厂
 getProductNameOrSteelMillsb(params,callback){
    baseApi.get('goods/goodstype/getProductNameOrSteelMillsb?lineId=' + params.lineId,null,callback)
 },
}

//抛出api 
export default getlineApi;
