import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Index.vue'

// import PersonalCenter from '../components/center/personalCenter.vue'
Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const routes = [
 
  {
   
    path: '/',
    name: 'Index',
    component: () => import('../views/Index.vue')
   
  },

  {
    meta: { needLogin: true },
    path: '/home',
    name: 'Home',
    redirect: '/home/personalCenter',
    component: () => import( /* webpackChunkName: "Home" */  '../views/Home.vue'),
    children:[
      // 个人中心
      {
        meta: { needLogin: true },
        path: 'personalCenter',
        name: 'PersonalCenter',
        component: () => import(/* webpackChunkName: "PersonalCenter" */  '../components/center/personalCenter.vue')
      },
      // 所属企业
      {
        meta: { needLogin: true },
        path: 'entrepreneur',
        name: 'Entrepreneur',
        component: () => import(/* webpackChunkName: "Entrepreneur" */'../components/center/entrepreneur.vue'),
      
      },
        // 未认证
        {
          meta: { needLogin: true },
          path: 'unverified',
          name: 'Entrepreneur',
          component: () => import(/* webpackChunkName: "Entrepreneur" */'../components/center/company/unverified.vue')
        },
        // 审核
        {
          meta: { needLogin: true },
          path: 'authenticated',
          name: 'Entrepreneur',
          component: () => import(/* webpackChunkName: "Entrepreneur" */'../components/center/company/authenticated.vue')
        },
        {
          meta: { needLogin: true },
          path: 'audit',
          name: 'Entrepreneur',
          component: () => import(/* webpackChunkName: "Entrepreneur" */'../components/center/company/audit.vue')
        },
      // 管理员权限
      {
        meta: { needLogin: true },
        path: 'administrator',
        name: 'Administrator',
        component: () => import(/* webpackChunkName: "Administrator" */'../components/center/administrator.vue')
      },
      // 普通用户
      {
        meta: { needLogin: true },
        path: 'general',
        name: 'Administrator',
        component: () => import(/* webpackChunkName: "Administrator" */'../components/center/adminroot/general.vue')
      },
      // 管理员用户
      {
        meta: { needLogin: true },
        path: 'admin',
        name: 'Administrator',
        component: () => import(/* webpackChunkName: "Administrator" */'../components/center/adminroot/admin.vue')
      },
      // 收支明细
      {
        meta: { needLogin: true },
        path: 'statement',
        name: 'Statement',
        component: () => import(/* webpackChunkName: "Statement" */'../components/center/statement.vue')
      },
      // 商品管理
      {
        meta: { needLogin: true },
        path: 'commodity',
        name: 'Commodity',
        component: () => import(/* webpackChunkName: "Commodity" */'../components/center/commodity.vue')
      },
     
      // 交易记录
      {
        meta: { needLogin: true },
        path: 'transaction',
        name: 'Transaction',
        component: () => import(/* webpackChunkName: "Transaction" */'../components/center/transaction.vue')
      },
      // 发布信息
      {
        meta: { needLogin: true },
        path: 'collect',
        name: 'Collect',
        component: () => import( /* webpackChunkName: "Collect" */'../components/center/collect.vue')
      },
      // 收藏
      {
        meta: { needLogin: true },
        path: 'release',
        name: 'Release',
        component: () => import(/* webpackChunkName: "Release" */'../components/center/release.vue')
      },
        // 聊天
  {
    path: 'contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "contact" */'../chatpage/chat/index.vue')
},
    ]
  },
  
  {
    path: '/search',
    name: 'Search',
    component: () => import(/* webpackChunkName: "Search" */'../views/search.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "Login" */'../views/Login.vue')
  },
  {
    path: '/iframeBack',
    name: 'iframeBack',
    component: () => import(/* webpackChunkName: "iframeBack" */'../components/center/adminroot/iframeBack.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import(/* webpackChunkName: "Register" */'../views/Register.vue')
  },
  {
    path: '/Forget',
    name: 'forget',
    component: () => import(/* webpackChunkName: "forget" */'../views/Forget.vue')
  },
  {
    path: '/aboutour',
    name: 'aboutour',
    component: () => import(/* webpackChunkName: "forget" */'../views/TopPages/aboutour')
  },
  {
    meta: { needLogin: true },
    path: '/Specialist',
    name: 'specialist',
    component: () => import(/* webpackChunkName: "specialist" */'../views/TopPages/Specialist')
  },
  {
    meta: { needLogin: true },
    path: '/SpecialistDetail',
    name: 'specialistDetail',
    component: () => import(/* webpackChunkName: "specialistDetail" */'../views/TopPages/SpecialistDetail')
  },
  {
    meta: { needLogin: true },
    path: '/Commodity',
    name: 'commodity',
    component: () => import(/* webpackChunkName: "commodity" */'../views/TopPages/Commodity.vue')
  },
    //  商品详情
    {
      meta: { needLogin: true },
      path: '/commodetial',
      name: 'commodetial',
      component: () => import(/* webpackChunkName: "commodetial" */'../components/center/commodetial.vue')
    },
  {
    meta: { needLogin: true },
    path: '/Issue',
    name: 'issue',
    component: () => import(/* webpackChunkName: "issue" */'../views/TopPages/Issue.vue')
  },
  {
    meta: { needLogin: true },
    path: '/Apply',
    name: 'apply',
    component: () => import(/* webpackChunkName: "apply" */'../views/TopPages/Apply.vue')
  },
  {
    meta: { needLogin: true },
    path: '/Exchange',
    name: 'exchange',
    component: () => import(/* webpackChunkName: "exchange" */'../views/TopPages/Exchange.vue')
  },
  {
   
    path: '/Pay',
    name: 'pay',
    component: () => import(/* webpackChunkName: "pay" */'../views/TopPages/Pay.vue')
  },
  {
   
    path: '/Privacy',
    name: 'privacy',
    component: () => import(/* webpackChunkName: "pay" */'../views/TopPages/Privacy.vue')
  },
  {
   
    path: '/Trime',
    name: 'trime',
    component: () => import(/* webpackChunkName: "pay" */'../views/TopPages/Trime.vue')
  },
  {
    meta: { needLogin: true },
    path: '/ForumDetial',
    name: 'ForumDetial',
    component: () => import(/* webpackChunkName: "ForumDetial" */'../views/TopPages/ForumDetial.vue')
  },
  {
   
    path: '/GoodsDetial',
    name: 'GoodsDetial',
    component: () => import(/* webpackChunkName: "GoodsDetial" */'../views/TopPages/GoodsDetial.vue')
  },
  {
    meta: { needLogin: true },
    path: '/OrderDetial',
    name: 'OrderDetial',
    component: () => import(/* webpackChunkName: "OrderDetial" */'../views/TopPages/OrderDetial.vue')
  },
  {
    meta: { needLogin: true },
    path: '/OrderResult',
    name: 'OrderResult',
    component: () => import(/* webpackChunkName: "OrderResult" */'../views/TopPages/OrderResult.vue')
  },
  {
    meta: { needLogin: true },
    path: '/ExchangeDetial',
    name: 'ExchangeDetial',
    component: () => import(/* webpackChunkName: "ExchangeDetial" */'../views/TopPages/ExchangeDetial.vue')
  },
  {
   
    path: '/StoreHome',
    name: 'StoreHome',
    component: () => import(/* webpackChunkName: "StoreHome" */'../views/TopPages/StoreHome.vue')
  },
  {
   
    path: '/AteelHome',
    name: 'AteelHome',
    component: () => import(/* webpackChunkName: "AteelHome" */'../views/TopPages/AteelHome.vue')
  },
  {
   
    path: '/Support',
    name: 'support',
    component: () => import(/* webpackChunkName: "support" */'../views/TopPages/Support.vue')
  },

  {
   
    path: '*',
    name: '404',
    component: () => import('../views/404.vue')
  },
 
  

]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})


// 路由守卫
// router.beforeEach((to,from,next)=>{
//   const isToken = localStorage .token?true:false
//   if(to.path=="/login"){
//    next()      
//   }else{
//     isToken ? next() : next("/login")
//   }
// })

export default router
