<template>
     <div class="adddialog">
    <el-dialog class="dialog" 
               :visible="addDialog"
               width="30%"
               :before-close="del"
                 :append-to-body="true"
               :close-on-click-modal="false" 
              >
               <div class="addconfirm">
            <div id="test1"></div>
            </div>
             <div class="addchecked" v-show="this.$store.state.home.agreement==='goodsRelease'">
                <el-checkbox v-model="checkeds"   @click='sure'  :checked="checkeds"></el-checkbox>&nbsp;&nbsp;我已阅读并同意商品须知
            </div> 
            <div class="addbutton"><el-button type="" @click="addsure">确认</el-button></div> 
    </el-dialog>
  </div>
</template>
<script>
import { Message } from "element-ui";
import Public from '../entre/public.vue'
import companyApi from '../../../api/companyApi'
export default {
    data () {
    return {
  
   checkeds:true
    }
  },
 
  props: {
     addDialog: {
      type: Boolean,
      default: false
    },
    totaldialog:"",
    checked:''
  },
  components:{
Public
  },

  methods: {
     sure(){
     this.checkeds=!this.checkeds
    },
    del(){
        this.$emit('del', false);
    },
    // 获取协议内容
    getAgreement(){
      companyApi.getAgreement({agreementId:this.$store.state.home.agreement},res=>{
        if(res.data.code=='200'){
           document.getElementById("test1").innerHTML = res.data.result;
        }
        else{
             Message.error(res.data.message);
        }
       
      })
    },
    addsure() {
// 企业认证协议：companyAut
// 企业认证授权书：companyAutcertificate 
// 商品发布协议：goodsRelease
// 平台服务协议：platformServices
// 隐私政策协议：privacy   
      if(this.$store.state.home.agreement==="goodsRelease"){
             if(this.checkeds==false){
        Message.error('点击阅读,才能确认');
        console.log("goodsRelease")
      }else{
     localStorage.setItem("goodsReleasesure", true);
      this.$emit('addsure', true);
      this.$store.commit("intaddpublic",true)
       this.$store.commit("spotpurchasing", false);
       this.$emit('del', false);
      }
   

      }
      else{
         this.$emit('del', false);
      }

    },
  }
}
</script>
<style lang="scss" scope>
::v-deep .el-dialog{
    min-width: 460px;
   
  }
  .dialog {
    text-align: center;
  
  .addconfirm{
width: 90%;
margin: 5px auto;
text-align: left;
 min-height: 300px;
  }}
  .addconfirm div{
    margin-top: 5px;
  }
  .addchecked{
      width: 70%;
  margin: 5px auto;
  text-align: left;
  height: 50px;
  color: #333333;
  font-size: 14px;
  .el-checkbox__inner{
    border-radius: 50%;
  }
  }
  .addbutton{
    margin: auto;
    width: 70%;
    // border: 1px solid red;
  }
.addbutton .el-button{
  width: 100%;
  margin-left: 0 !important;
   background: #e94f55;
   color: white;
}
.addbutton .el-button:hover{
    background: #e94f55;
      color: white;
    
}

   
</style>