const applocationCfg = {
    applocation: "prod",
    test: {
        // url:"http://106.14.115.59",
        url: "http://139.196.201.172:8080",
        im: {
            appkey: "1101210816097601#demo",
            Host: "https://a1.easemob.com/",
        }
    },
    prod: {
        url: "https://www.wyw666.com/api",
        im: {
            appkey: "1117210826200176#im",
            Host: "https://a31.easemob.com",

        }
    },
    getBaseApi() {
        //test
        return applocationCfg.getUrl() + "/";
    },
    getUrl() {
        return applocationCfg[applocationCfg.applocation].url;
    },
    getImCfg() {

        return applocationCfg[applocationCfg.applocation].im;
    }
}
export default applocationCfg;
