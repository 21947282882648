<template>
    <div class="appq">
      <div class="appleft">
     <el-carousel indicator-position="outside"   >
   <el-carousel-item v-for="(item,i) in imagesList" :key="i">
    <img class="maximg"  @click="imageurlrouter(item)" :src="item.image"  />
    </el-carousel-item>
  </el-carousel> 
        <div class="seiperleft">
         <img class="imgggg" src="../../assets/index/seiperleft.jpg" alt="">
        </div> 
        </div>
        <div class="swiperright">
     <img src="../../assets/index/swiperright.jpg" alt="">
        </div>
    </div>
</template>
<script>
import sildeApi from "../../api/slideApi"

export default {
    data(){
      return{
         imagesList:[],
      }
    },
    created(){
      this.getimagelistbytype()
    },
    methods:{
// 轮播跳转
imageurlrouter(item){
  
   if(item.funckey=="goods"){
       this.$router.push(`/GoodsDetial?id=${item.param}`)
   }
   else if(item.funckey=="enterprise"){
  this.$router.push(`/StoreHome?id=${item.param}`)
   }
   else if(item.funckey=="helper"){
        this.$router.push(`/support`)
   }
   else{
        window.open(item.param)
   }
},
      getimagelistbytype(){
       sildeApi.getimagelistbytype("",(res)=>{
          if(res.data.code==200){
     this.imagesList = res.data.result
         }
           

         })
      }
      
    }
}
</script>
<style scoped>

.appq{
  /* border: 1px solid red; */
  display: flex;
  justify-content: space-between;
  position: relative;
}
.appleft{
  width: 60%;
}
.maximg{
  width: 100%;
  cursor: pointer;
}
.el-carousel {
    width:117%;
    height:330px;
    margin-top: 0px; 
}
::v-deep .el-carousel__container{
  height: 330px !important;
}

.seiperleft img{
   height: 137px;
   width: 117%;
   margin-top: 5px;
}
.swiperright{
  width: 29%;
}
.swiperright img{
  height: 470px;
  width: 100%;

}

::v-deep .el-carousel__indicators--outside{
  position: absolute !important;
  bottom: 20px;
}
 @media screen and (max-width: 1800px) {
      .el-carousel {
    width:118%;
    height:340px !important;
}
::v-deep .el-carousel__container{
   height:340px !important;
}
.seiperleft img{
   height: 130px;
  width:118%; 
}
.maximg{
  width: 115%;
}


 } 
 /*
 @media screen and (min-width: 1823px) {
     .swiperright img{
  height: 470px;
  width: 230px;

}
 }
 @media screen and (min-width: 1351px) and (max-width: 1822px){
   .el-carousel {
    width:118%;
    height:380px !important;
}
::v-deep .el-carousel__container{
   height:340px !important;
}
.seiperleft img{
   height: 130px;
  width:118%; 
}
.maximg{
  width: 110%;

}
.imgggg{
  position: relative;
  top: -40px;
}
.swiperright img{
  height: 470px;
  width: 200px;

}
::v-deep .el-carousel__indicators--outside{
  position: absolute !important;
  bottom: 45px;
}
 } */
</style>