import baseApi from './baseApi.js';
const goodsApi = {
    //优选商品
    getOptimumList(params, callback) {
        baseApi.get("goods/search/optimumList?pageNum=" + params.pageNum + "&pageSize=" + params.pageSize,null, callback)
    },
    // 获取优选商家
    optimizationCompany(params,callback){
        baseApi.get('goods/search/optimizationCompany',params,callback)
       },
     // 获取商品参数
 getGoodsParam(params,callback){
    baseApi.get('goods/goodsManager/getGoodsParam?pageNum='+params.pageNum+'&pageSize='+params.pageSize,null,callback)
   },
   // 添加商品
   addGoods(params,callback){
    baseApi.post('goods/goodsManager/addGoods',params,callback)
   },
      // 添加现货采购
      getSpotsourcingList(params,callback){
        baseApi.post('center/spotsourcing/addSpotsourcing',params,callback)
       },
//    根据状态获取商品类表
getGoodsListByState(params,callback){
    baseApi.get('goods/goodsManager/getGoodsListByState?pageNum='+params.pageNum+'&pageSize='+params.pageSize + "&state=" + params.state,null,callback)
   },
//    搜索中的优选商品商品
searchoptimumList(params, callback) {
    baseApi.get("goods/search/optimumList?pageNum=" + params.pageNum + "&pageSize=" + params.pageSize,null, callback)
},
// 根据参数获取商品列表

List(params, callback) {
    baseApi.get("goods/search/list?pageNum=" + params.pageNum + "&pageSize=" + params.pageSize+"&param="+params.params, null,callback)
},
// 搜索获取商品详情页数据
searchdetails(params,callback){
    baseApi.get('goods/search/details?id='+params.id,null,callback)
   },

//    获取商品详情页数据
goodsdetails(params,callback){
    baseApi.get('goods/goodsManager/details?id='+params.id,null,callback)
   },

//    修改商品
updateGoods(goodid,params,callback){
    baseApi.post('goods/goodsManager/updateGoods?id='+goodid.id,params,callback)
   },
//   通过id获取商品信息  用于修改
getGoodsInfo(params,callback){
    baseApi.get('goods/goodsManager/getGoodsInfo?id='+params.id,null,callback)
   },

   // 编辑商品(已通过)
 editteGoods(params,callback){
    baseApi.post('goods/goodsManager/editteGoods?id='+params.id+'&price='+params.price+'&number='+params.number,null,callback)
   },
   // 上架商品
   putShelf(params,callback){
    baseApi.post('goods/goodsManager/putShelf?id='+params.id,null,callback)
   },
   // 下架商品
   offShelf(params,callback){
    baseApi.post('goods/goodsManager/offShelf?id='+params.id,null,callback)
   },
   // 删除商品
   cancelGoods(params,callback){
    baseApi.post('goods/goodsManager/cancelGoods?id='+params.id,null,callback)
   },
    // 上传文件接口
    uploadFile(params, callback) {
        baseApi.postImage('goods/goodsManager/addBatchGoods',params,callback)
    },
//    商品拒绝原因 
  
getGoodsRefuseReason(params,callback){
 baseApi.get('center/platformfeedback/getGoodsRefuseReason?id='+params.id,null,callback)
},
// 商品禁用原因
getGoodsDisableReason(params,callback){
    baseApi.get('center/platformfeedback/getGoodsDisableReason?id='+params.id,null,callback)
   },
//   获取商品收藏列表
getCollectRecordList(params,callback){
    baseApi.get('center/collectrecord/getCollectRecordGoods?page='+params.page+'&size='+params.size,null,callback)
   },
   //  获取信息收藏列表
   getCollectRecordInformation(params,callback){
    baseApi.get('center/collectrecord/getCollectRecordInformation?page='+params.page+'&size='+params.size,null,callback)
   },
//    添加收藏
addCollectRecord(params,callback){
    baseApi.post('center/collectrecord/addCollectRecordGoods?sourceId='+params.sourceId,null,callback)
   },
//  删除收藏
delCollectRecord(params,callback){
    baseApi.post('center/collectrecord/delCollectRecordGoods?sourceId='+params.sourceId,null,callback)
   }, 
    // 获取当前商品收藏状态
    getCollectRecordState(params,callback){
        baseApi.get('center/collectrecord/getCollectRecordStateGoods?sourceId='+params.sourceId,null,callback)
       },


}



//抛出api 
export default goodsApi;

