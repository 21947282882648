<template>

    <div class="foo">
      <div class="centera">
         <div class="hope">
             需要更多帮助?  立即<router-link to="/support"><a href=""> 在线交流</a></router-link>或致电 4006008891
         </div>
        
         <div class="copy">
              <a target="_blank" href="https://beian.miit.gov.cn/" >沪ICP备20017545号-3</a> 
             <router-link to="/aboutour"> <a href="" class="aboutour">关于我们</a></router-link>
         </div>
      </div>
    </div>
 
</template>
<script>
export default {
    
}
</script>
<style scoped>
.aboutour{
    margin-left: 10px;
}
.foo{
   
    width:100%;
    height: 130px;
    background-color: rgb(245, 245, 245);
    font-size: 16px;
    min-width: 1380px;
    /* border: 1px solid red; */
    
}

.centera{
    margin: 0 auto;
    width: 40%;
    padding-top: 38px; 
    text-align: center;
    min-width: 1000px;
    /* border: 1px solid red; */
    color: rgb(68, 68, 68);
    font-size: 14px;
    line-height: 14px;
}
.hope{
     width: 40%;
    margin: 0 auto;
}
.copy{

     width: 40%;
    margin: 10px auto;
}
.middle{
       display: flex;
       /* justify-content: space-between; */
           width: 20%;
          /* margin-left: 18%; */
          /* border: 1px solid red;  */
          margin: 10px auto;
          text-align: center;
          
    }
.middle li{
    list-style: none;
      /* padding-right: 5px; */
      /* border-right: 1px solid black; */
    /* width: 100px; */
      text-align: center;
      width: 80px;
    
      
}
</style>