<template>
<div>
   
  <div class="top">
 
    <div class="list">
      <div class="item">
        <div class="titless">金属尾货交易平台</div>
         
        <div class="wei">
          <router-link
            to="/"
            :class="{ active: $store.state.currPage == 'Index' }"
            class="title a"
          >
            首页</router-link
          >
        </div>
        <ul>
          <li >
            <router-link
              to="/specialist"
              class="a"
              :class="{ active:$store.state.currPage == 'specialist' }"
              >专家咨询</router-link
            >
          </li>
          <li @click="csi">现货求购</li>
          <li @click="colle">
            <a
            
              class="a"
              :class="{ active: $store.state.currPage == 'Commodity' }"
            >
              商品管理</a
            >
          </li>
          <li>
            <router-link
              to="/home/entrepreneur"
              class="a"
              :class="{ active: $store.state.currPage == 'Entrepreneur' }"
              >企业管理</router-link
            >
          </li>
        
            <li >  <router-link to="/exchange" class="a" :class="{ active: $store.state.currPage == 'exchange' }">
              交流广场</router-link
          >
            </li>
        </ul>
        <el-dropdown>
          <span class="el-dropdown-link">
            <router-link
              to="/home"
              class="a"
              v-if="this.$store.state.home.isLogin"
              >{{
                !this.$store.state.home.nickName
                  ? "管理中心"
                  : this.$store.state.home.nickName
              }}</router-link
            >
            <router-link to="/login" class="a" v-else>登录/注册</router-link>
            <i class="el-icon-caret-bottom el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              ><router-link to="/login" class="a"
                ><li class="out hope" @click="out" v-if="this.$store.state.home.isLogin">
                  退出登录
                </li></router-link
              ></el-dropdown-item
            >
               <el-dropdown-item>
             
               <router-link to="/home" class="a hope"   v-if="this.$store.state.home.isLogin">
                <li>管理中心</li></router-link
              >
              </el-dropdown-item
            >
            <el-dropdown-item>
              <router-link to="/support" class="a hope">
                <li>帮助中心</li></router-link
              >
              
              </el-dropdown-item
            >
           
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <Msg :dialogVisible="dialogVisible" @clear="clear" @sure="sure"></Msg>
  </div>
  </div>
</template>
<script>
import loginApi from "../../api/loginApi";
import Msg from "../../views/TopPages/Commodity.vue";
import { Message } from "element-ui";
export default {
  data() {
    return {
      pmhome:"",
      // act_index:1,
      // act_ind:1,
      top: false,
      dialogVisible: false,
    };
  },
  mounted() {
  
  },
  components: {
    Msg,
  },
  methods: {

    out() {
      WebIM.isLogin = false
      const that = this;
      loginApi.removeLogin("", function (res) {
         localStorage.removeItem("userInfo")
           localStorage.removeItem("messagepublic")
        localStorage.removeItem("Login_data"),
        sessionStorage.setItem("imlogin",false)
         that.$router.push("/login");
        that.$router.go(0);
      });
    },
    //采购对话框
    csi() {
      if (JSON.parse(localStorage.getItem("Login_data"))) {
        if(this.$store.state.home.userInfostate=='companyAdmin'){
              this.dialogVisible = true;
        }
        else{
            Message.error("无权限")
        }
        
      }
      else{
        Message.error("请先登录")
       
        this.$router.push("/login");
      }
        
    },
    colle() {
       if(this.$store.state.home.userInfostate=='companyAdmin'){
              this.$router.push("/home/Commodity")
       }
       else{
          Message.error("无权限")
       }
     
    },

    clear(bool) {
      this.dialogVisible = bool;
    },
    sure(bool) {
      this.dialogVisible = bool;
    },
  },
};
</script>
 <style lang="scss" scoped>

.nack {
  cursor: pointer;
  width: 60px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.el-icon-circle-close{
  font-size: 16px;
}
.el-dropdown {
  font-size: 18px;
  // border: 1px solid red;
  .el-dropdown-link {
    // border: 1px solid yellow;
    width: 100px !important;
    color: white;
  }
}
.out {
  cursor: pointer;
  width: 80px;
  /* border: 1px solid blue; */
}

.hope {
  color: black;
}

.title {
  /* border: 1px solid red; */
  display: flex;
  margin-top: -5px;
  /* width: 100px; */
  /* justify-content: space-around; */
}
.title div:nth-child(2) {
  padding-left: 10px;
  /* margin-left: 10px; */
  margin-top: 7px;
  border-left: 1px solid white;
  height: 20px;
  line-height: 20px;
}
.title div:nth-child(1) {
  margin-right: 10px;
  /* margin-left: 10px; */
  line-height: 40px;
}
.list {
    height: 70px;
  background: #e94f55;
  /* border: 1px solid blue; */
  min-width: 1380px;
}
.wei {
  // width: 127px;
  // height: 30px;
  padding-top: 5px;
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  // line-height: 40px;
  //  border: 1px solid red; 
  text-align: center;
}
.list .item {
  display: flex;
  width: 70%;
  height: 100%;
  margin: 0 auto;
  /* padding-top: 20px; */
  align-items: center;
  //  border:  1px solid black;
 
  justify-content: space-between;
  color: white;
  min-width: 1000px;
  .titless {
    // font-family: cursive;
    font-family: PingFangSC-Regular, PingFang SC;
 font-size: 24px;
    width: 220px;
    // border: 1px solid red;
   
  }
}
.list .item ul {
  display: flex;
  justify-content: space-between;
  // border: 1px solid red;
  /* align-items: center; */
  width: 40%;
  min-width: 540px;
 
}
.list .item li {
  // width: 100px;
  height: 28px;
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 28px;
  list-style: none;
    //  cursor: pointer;
    cursor: pointer !important;
  // border: 1px solid red;
}
.a {
  text-decoration: none;
  color: white;
}
.hope {
  color: black;
}
.active {
  // color: blue !important;
  // font-weight: 600;
  border-bottom: 1px solid white;
}
</style>