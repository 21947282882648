<template>
  <div class="homeheader">
    <!-- 首页头部 -->
    <div class="content">
      <div class="left">
        <img src="../../assets/index/login.png" alt="">
      </div>
      <div class="center">
        <el-input placeholder="请输入品名、材质、规格、钢厂等 例如：彩涂0.5*1250*C" @focus="cleanVerify" v-model="searchinput"
          @keyup.enter.native="searchinp" clearable>
        </el-input>
        <el-button type="danger" @click="searchinp">搜索</el-button>
        <div class="searchtitle">
          普卷 &nbsp;&nbsp; 彩涂 &nbsp;&nbsp; 普板 &nbsp;&nbsp; 镀锌卷 &nbsp;&nbsp; 锌铝镁 &nbsp;&nbsp; 不锈钢 &nbsp;&nbsp; 低合金板
        </div>
      </div>
      <router-link to="/support" class="right1a">
        <div class="right1">
          <div class="top">
            点击咨询或致电
          </div>
          <div class="bottom">
            <img src="../../assets/index/phone.png" alt="">
            <span> 4006008891</span>
          </div>
        </div>
      </router-link>
    </div>
    <!-- <div class="bottom">
      <div class="categorytitle">
         <div class="img"><img src="../../assets/index/top.png" alt=""></div>
         <div>所有商品分类</div>
      </div>
      <div class="tip">
          SPHC | dx51d | 590 | 980 | 420 |邯郸恒生 冷轧板卷
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchinput: "",
    };
  },
  methods: {
    searchinp() {
      if (this.searchinput) {
        this.$uweb.trackEvent("搜索行为", "搜索", this.searchinput)
      }

      this.$router.push({ path: '/search', query: { scarch: this.searchinput } })
    },
    cleanVerify(event) {
      let input = event.currentTarget
      input.style.border = "none"
    }
  }
};
</script>

<style lang="scss" scoped>
.homeheader {

  // border-bottom: 2px solid #e95157;
  .categorytitle {
    font-size: 16px;
    display: flex;
    justify-content: start;
    align-items: center;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.56);

    .img {
      padding: 0 10px;
    }
  }

  .content {
    width: 65%;
    height: 99px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 740px;

    .left {
      box-sizing: border-box;
      width: 200px;

      // padding: 0px 13px 0px 14px;
      //  border: 1px solid red;
      img {
        width: 140px;
        // height: 70px;
        vertical-align: middle;
        padding-left: 30px;
        // border: 1px solid red;

      }

    }

    .center {
      position: relative;
      width: 700px;
      box-sizing: content-box;
      margin-left: 46px !important;
      height: 40px;
      border-radius: 3px;
      border: 2px solid #e94f55;
      display: flex;
      justify-content: start;
      flex-wrap: nowrap;

      .el-input {
        border: none;
      }

      .searchtitle {
        position: absolute;
        top: 46px;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Light, PingFang SC;
        font-weight: 300;
        // color: #666666;
        line-height: 20px;
      }

      .el-button--danger {
        border-radius: 0px;
        font-size: 16px;
        color: #e94f55ff;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
      }

      .el-button:hover {
        border-radius: 0px;
        font-size: 16px;
        background-color: rgb(245, 108, 108) !important;
        border: 1px solid transparent;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
      }
    }

    .right1a {
      text-decoration: none;
    }

    .right1 {
      width: 117px;
      display: flex;
      padding-left: 29px;
      flex-wrap: wrap;
      // border: 1px solid red;
      // align-items:center;
      padding-top: 10px;

      .top {
        height: 22px;
        line-height: 22px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.56);
      }

      .bottom {
        color: #d0111aff;
        font-size: 18px;
        height: 34px;
        // line-height: 34px;

        font-weight: 600;
        width: 100%;
        margin-left: -22px;

        img {
          vertical-align: middle;
          width: 20px;
          height: 20px;
          margin-right: 5px;
        }

      }
    }
  }

  .bottom {
    position: relative;
    width: 60%;
    margin: 0 auto;
    height: 43px;
    display: flex;
    justify-content: start;

    .categorytitle {
      width: 200px;
      height: 43px;
      background: #F8F8F8;
      // margin-left: 33px;

    }

    .tip {
      position: absolute;
      bottom: 37px;
      left: 14%;
      margin-left: 101px;
      width: 322px;
      height: 17px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.56);
      line-height: 17px;

    }
  }
}
</style>
