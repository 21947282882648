import baseApi from './baseApi.js';
const sildeApi = {
   
    getimagelistbytype(params,callback){
    baseApi.get('center/imageconfig/getimagelistbytype?type=0',params,callback)
 },

}

//抛出api 
export default sildeApi;
