import Vue from 'vue'
import Vuex from 'vuex'
import Home from "./home"

import Chat from "./chat";
import FriendModule from "./friendModule";
import Group from "./group";
import Emedia from "./emedia";
import Agora from "./agora"
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    home: Home,
   
    chat: Chat,
    friendModule: FriendModule,
    group: Group,
    emedia: Emedia,
    agora: Agora
  }
})
