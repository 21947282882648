
<template>
  <div class="page-wrap">
    <ul>
      <li v-if="prepage" class="li-page cupour">&laquo;</li>
       <li v-else class="li-page" @click="goPrePage">&laquo;</li>
      <li v-for="(i, index) in showPageBtn" :key="index"
        :class="{active: i === currentPage, pointer: i, hover: i && i !== currentPage}" @click="pageOffset(i)">
        <a v-if="i">{{i}}</a>
        <a v-else>···</a>
      </li>
      <li v-if="nextpage" class="li-page cupour">&raquo;</li>
        <li v-else class="li-page" @click="goNextPage">&raquo;</li>
      
       <span>共{{totalPageCount}}页</span>
    </ul>
  </div>
</template>
<script>
  export default {
    name: "pagination",
    props: {
      num: Number,
      limit: Number,
     totalPageCount:Number,
     totalCount:Number,
    },
    data: () => ({
      offset: 0,
      prepage:true,
      nextpage:false
    }),
    computed: {
      prePage() {
        return this.offset !== 0 && this.num;
      },
      nextPage() {
        return (this.offset + this.limit < this.num) && this.num
      },
      totalPage() {
        return Math.ceil(this.num / this.limit)
      },
      currentPage() {
        return Math.ceil(this.offset / this.limit) + 1
      },
      showPageBtn() {
        const pageNum = this.totalPage;
        const index = this.currentPage;
        if (pageNum <= 11) return [...new Array(pageNum)].map((v, i) => i + 1);
        if (index <= 2) return [1, 2, 3,4,5,6,7,8,9,10, 0, ];
        if (index >= pageNum - 5) return [1, 0,pageNum - 6,pageNum - 5,pageNum - 4,pageNum - 3, pageNum - 2, pageNum - 1, pageNum];
        if (index === 3) return [1, 2, 3,4,5,6,7,8,9,10, 0,];
        if (index === pageNum - 8) return [1, 0,pageNum - 9,pageNum - 8,pageNum - 7,pageNum - 6, pageNum - 5, pageNum - 4,pageNum - 3,0];
        return [1, 0, index - 1, index, index + 1,index + 2,index + 3,index + 4,index + 5, 0]
      }
    },
    methods: {
      pageOffset(i) {
       
        if(i==this.totalCount){
          this.$parent.contentoptimum()
          setTimeout(()=>{
              this.$parent.contentoptimum()
          },100)
        }
        if(i>this.totalCount){
           this.$parent.allList(i) 
        }
        if(i==1){
              this.prepage = true
        }else{
          this.prepage = false
        }
        if(i==this.totalPageCount){
         this.nextpage = true
        }
        else{
           this.nextpage = false
        }
        if (i === 0 || i === this.currentPage) return;
        this.offset = (i - 1) * this.limit;
        this.$parent.change(i)
      },
      goPrePage() {
         this.nextpage = false
        if(this.currentPage==2){
            this.prepage = true  
        }
      if(this.currentPage-1==this.totalCount){
          this.$parent.contentoptimum()
          setTimeout(()=>{
              this.$parent.contentoptimum()
          },100)
        }
        if(this.currentPage-1>this.totalCount){
           this.$parent.allList(this.currentPage-1) 
        }
       
        this.offset -= this.limit;
         this.$parent.change(this.currentPage)
      },
      goNextPage() {
       if(this.currentPage+1==this.totalCount){
          this.$parent.contentoptimum()
          setTimeout(()=>{
              this.$parent.contentoptimum()
          },100)
        }
        if(this.currentPage+1>this.totalCount){
           this.$parent.allList(this.currentPage+1) 
        }
        this.prepage = false
        if (this.currentPage + 1==this.totalPageCount) {
          this.nextpage = true
        }
        else{
            this.nextpage = false
        }
        this.offset += this.limit;
         this.$parent.change(this.currentPage)
      
        // this.$emit('getNew', this.offset);
      }
    }
  }

</script>

<style scoped>
  .li-page {
    line-height: 1.5;
    cursor: pointer;
    color: #c2c4cc;
    background-color: #f4f4f5;
  }
.cupour{
  cursor: not-allowed;
 
 
}
  .active {
    /* border-color: #409eff; */
    background-color: rgb(255,0,0);
  }

  .pointer {
    cursor: pointer;
  }
  .hover {
    color: #FFF;
    background-color: #f4f4f5;
  }
  .active a {
    color: #FFF;
  }

  .page-wrap {
  
     margin: 0px auto;
    padding: 30px 0; 
    /* border: 1px solid red; */
    font-size: 13px;
    margin-top: 10px;
  }

  .page-wrap ul {
     /* border: 1px solid red; */
    
    margin: 0 auto;
    text-align: center;
    list-style: none;
    overflow: hidden;
    padding-inline-start: 0px;
  }

  .page-wrap li {
    display: inline-block;
    color: black;
    padding: 3px 10px !important;
    margin: 0 5px !important;
    border-radius: 3px;
    user-select: none;
    border: 1px solid transparent;
     /* margin-top: 2px; */
  }
  .page-wrap li a{
    margin-right: 0 !important;
  }
   .page-wrap span{
     display: inline-block;
       /* padding: 5px 10px; */
    margin: 0 5px;
    font-weight: 400;
    letter-spacing: 1px;
color: rgba(0, 0, 0, 0.56);
   }

</style>
