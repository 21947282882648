import baseApi from './baseApi.js';
const companyApi = {
// 获取企业认证手机验证码
sendVerifyCode(params,callback){
	baseApi.post('center/company/sendVerifyCode?phone=' + params.phone,null,callback)
},
	// 获取当前企业信息
	getCurrCompanyInfo(params,callback){
		baseApi.get('center/company/getCurrCompanyInfo',params,callback)
	},
	// 获取企业审核拒绝原因
	getCompanyRefuseReason(params,callback){
		baseApi.get('center/platformfeedback/getCompanyRefuseReason',params,callback)
	},
		// 获取企业禁用原因
		getCompanyDisableReason(params,callback){
			baseApi.get('center/platformfeedback/getCompanyDisableReason',params,callback)
		},
		

	// 取消企业申请
	cancelCreateCompany(callback){
		baseApi.post('center/company/cancelCreateCompany',null,callback)
	},
	// 获取当前企业状态
	getCurrCompanySate(callback){
		baseApi.get('center/company/getCurrCompanySate',null,callback)
	},
	//申请管理员
	 addAdminRecord(params,callback){
		 baseApi.post('center/company/addAdminRecord?certtype='+params.certtype+'&colicense='+params.colicense+'&authorization='+params.authorization,null,callback)
	 },
	 // 申请旗舰店
	 addFlagshipRecord(params,callback){
		 baseApi.post('center/company/addFlagshipRecord?low=' + params.low + "&high=" + params.high,null,callback)
	 },
	//  获取当前企业申请旗舰店状态
	getFlagShipState(params,callback){
		baseApi.get('center/company/getFlagShipState',params,callback)
	},
	// 创建企业
	createCompany(params,callback){
		baseApi.post('center/company/createCompany',params,callback)
	},
	// 重新提交创建企业
	reCreateCompany(params,callback){
		baseApi.post('center/company/reCreateCompany',params,callback)
	},
	// 修改企业信息
	updateCompany(params,callback){
		baseApi.post('center/company/updateCompany',params,callback)
	},
	// 退出企业
	outCompany(params,callback){
		baseApi.post('center/conpanyStaff/outCompany',params,callback)
	},
	// 获取协议内容
	getAgreement(params,callback){
		baseApi.get('center/agreementitem/getAgreementItemByAgreementId?agreementId=' + params.agreementId,null,callback)
	},
	// 旗舰店拒绝理由
	getFlagShipRefuseReason(params,callback){
		baseApi.get('center/platformfeedback/getFlagShipRefuseReason?id=' + params.id,null,callback)
	},
}
//抛出api 
export default companyApi