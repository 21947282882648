const Home = {
    state: {
        Loginphone:localStorage.getItem("Loginphone")?JSON.parse(localStorage.getItem("Loginphone")):null,
        token: localStorage.getItem("Login_data") || '',
        nickName:Boolean(localStorage.getItem('nickName'))?localStorage.getItem('nickName'):'',
        isLogin: Boolean(localStorage.getItem('Login_data')),
        act_index:1,
        currPage:"",
        userInfostate:localStorage.getItem('userInfostate'),
        // 审核状态
        companystate:localStorage.getItem('companystate'),
        // 添加商品状态
        addpublic:false,
        // 采购
        spotpurchasing:false,
        username: "",
	     	userDetail:{},
        //  隐私协议
         agreement:"",   
        //是否法人
        proposer:localStorage.getItem('proposer'),
        // 关闭iframe  刷新页面
        iframestate:"",
        imlogin:false,
        // 发布/编辑
        announce:true
      },
      mutations: {
        initannounce(state,payload){
          state.announce = payload
        },
        initUser(state,payload){
          state.Loginphone = payload
        },
        initnickName(state,payload){
          localStorage.setItem('nickName',payload)
          state.nickName=payload
        },
        inituserInfostate(state,payload){
          localStorage.setItem('userInfostate',payload)
          state.userInfostate=payload
        },
        intaddpublic(state,payload){
          state.addpublic = payload
        },
        intagreement(state,payload){
          state.agreement = payload
        },
        intiframestate(state,payload){
          state.iframestate = payload
        },
        spotpurchasing(state,payload){
          state.spotpurchasing = payload
        },
        initLogin(state,payload){
          localStorage.setItem("Login_data",payload);
          state.isLogin = Boolean(localStorage.getItem('Login_data'));
      
        },
        setUserName(state, username) {
          state.username = username;
        },
        setRegisterFlag(state, flag) {
          state.isRegister = flag;
        },
        setUserDetaild(state,user_detail){
          state.userDetail = user_detail
        }
        // isActive(state,payload){
          
        // }
      },
      actions: {
        
        onLogin: function (context, payload) {
          context.commit("setUserName", payload.username);
          var options = {
            user: payload.username,
            pwd: payload.password,
            appKey: WebIM.config.appkey,
            apiUrl: 'https://a1.easecdn.com'
          };
          WebIM.conn.open(options);
          localStorage.setItem("userInfo", JSON.stringify({ userId: payload.username, password: payload.password }));
        },
        onLogout: function (context) {
          context.commit("setUserName", "");
          localStorage.setItem("userInfo", "");
          WebIM.conn.close();
        },
        onRegister: function (context, payload) {
          const _this = this;
          // context.commit('setUserName', payload.username)
          var options = {
            username: payload.username,
            password: payload.password,
            nickname: payload.nickname,
            appKey: WebIM.config.appkey,
            success: () => {
              Message({
                type: "success",
                message: "注册成功"
              });
              context.commit("setRegisterFlag", false);
            },
            error: (err) => {
              if (JSON.parse(err.data).error == "duplicate_unique_property_exists") {
                Message.error("用户已存在！")
              } else if (JSON.parse(err.data).error == "illegal_argument") {
                if (JSON.parse(err.data).error_description === 'USERNAME_TOO_LONG') {
                                return message.error('用户名超过64个字节！')
                            }
                Message.error("用户名不合法！")
              } else if (JSON.parse(err.data).error == "unauthorized") {
                Message.error("注册失败，无权限！")
              } else if (JSON.parse(err.data).error == "resource_limited") {
                Message.error("您的App用户注册数量已达上限,请升级至企业版！")
              }
            }
          };
          WebIM.conn.registerUser(options);
        },
        getLoginUserInfo:(context,payload)=>{
          const { userId } = payload
          WebIM.conn.fetchUserInfoById(userId).then((res) => {
            console.log(res.data[userId])
            let user_detail = res.data[userId];
            res.data[userId] && context.commit('setUserDetaild',user_detail)
          })
        },
        updateOwnUserInfo:({commit},payload)=>{
          const {infoValue,type} = payload;
          WebIM.conn.updateOwnUserInfo(type,infoValue).then((res) => {
            res.data &&commit('setUserDetaild',res.data)
          })
        },
        setRegisterFlag: function (context, flag) {
          const path = flag ? "/register" : "/login";
          Vue.$router.push(path);
          context.commit("setRegisterFlag", flag);
        }
    

      },
      getters:{
        getLoginphone(state){
          return state.Loginphone
        }
      },
}
export default Home