import baseApi from './baseApi.js';
const loginApi = {
 // 注册验证码
 getRegisterCode(params, callback) {
  baseApi.post("center/login/sendregisteredcode?phone="+params, null,callback);
 },
 // 找回密码验证码
 getPwdCode(params, callback) {
  baseApi.post("center/login/sendretrievepwdcode?phone="+params.phone,null, callback);
 },
 // 登录验证码
 getLoginCode(params, callback) {
  baseApi.post("center/login/sendlogincode?phone="+params, null,callback);
  
 },
 // 找回密码
 getFindPwd(params, callback) {
  baseApi.post("center/login/retrievepassword?phone="+params.phone+"&code="+params.code+"&password="+params.password, params, callback);
 },
 // 注册账户
 addUser(params, callback) {
  baseApi.post("center/login/registeraccount?phone="+params.phone+"&code="+params.code+"&password="+params.password,null,callback);
 },
 // 用户登录
 getLogin(params, callback) {
  baseApi.post('center/login/login', params, callback);
 },
 // 移除用户登录
 removeLogin(params, callback) {
  baseApi.post('center/login/logout', params, callback);
 },
 //  获取公告
// 公告
getProclamationInfo(params,callback){
    baseApi.get('center/proclamation/getProclamationInfo?uuid='+params,null,callback)
 },
// 检测登录状态
checkLogin(params, callback) {
    baseApi.get('center/login/checkLogin', params, callback);
   },
};
//抛出api 
export default loginApi;